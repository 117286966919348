import React, {useEffect, useState} from 'react';
import {Accordion, AccordionPanel, Card, Icon} from '@salesforce/design-system-react';
import {SCPDeployment, SCPOperation} from "../../../../utils/types/deployments/scp/operations";
import ThemedBadge from "../../../../utils/components/tag/tag";
import {CONSTANT_GENERIC_DEPLOYMENTS} from "../../../../constants/themes";
import {timestampStringToDate} from "../../../../utils/types/deployments/stacksets/deployment";
import BarChart, {BarChartRow, PrepareBarChartData} from "../../../../utils/components/bar_chart/bar_chart";

interface StaggerCardProps {
    selectedDeployment: SCPDeployment | null;
    selectedOperationID: string;
    getDetailsLi: (label: string, value: string) => JSX.Element;
}

const SCPMPAsCard: React.FC<StaggerCardProps> = ({
                                                           selectedDeployment,
                                                     selectedOperationID,
                                                           getDetailsLi,
                                                       }) => {

    const [selectedMPA, setSelectedMPA] = useState<string>("");
    const [mpaBarChartData, setMpaBarChartData] = useState<BarChartRow[]>([])

    useEffect(() => {
        if (!selectedDeployment || !selectedOperationID) {
            return
        }
        const mpas = selectedDeployment.getMPAsForOperationID(selectedOperationID)
        mpas.length == 1 && setSelectedMPA(mpas[0])
    }, [selectedDeployment, selectedOperationID]);

    useEffect(() => {
        if (!selectedDeployment || selectedOperationID == "") {
            return;
        }
        const statusCounts = new Map<string, number>();

        // Iterate over all deployments
        selectedDeployment.getMPAsForOperationID(selectedOperationID).forEach((mpa) => {
            const status = selectedDeployment.getOperationStatusForMPA(selectedOperationID, mpa) || "Unknown";
            statusCounts.set(status, (statusCounts.get(status) ?? 0) + 1);
        });
        setMpaBarChartData(PrepareBarChartData(statusCounts));
    }, [selectedOperationID]);

    // Ensure data is available before rendering
    if (!selectedDeployment || selectedOperationID == "") {
        return null;
    }


    if (selectedDeployment.getMPAsForOperationID(selectedOperationID).length == 0) {
        return <>
            <Card className="" bodyClassName="" hasNoHeader={true}>
                <p>No MPAs were affected by this operation!</p>
            </Card>
        </>
    }

    const getMPAsBarChartSize = () => {
        return `slds-size_1-of-${mpaBarChartData.length <= 0 ? 1 : mpaBarChartData.length}`
    }

    const getPanelBody = () => {

    }

    return <>
        <Card
            heading="Changes"
            icon={<Icon category="standard" name="code_set" size="small"/>}
            className={"slds-m-right--small slds-m-left_small slds-m-bottom_small"}
            bodyClassName={"slds-p-left--large slds-p-right--large slds-p-bottom--large slds-p-top--x-small"}
            style={{minHeight: "50vh"}}
        >
            {
               <>
                    <div className="slds-grid slds-wrap">
                        <div className="slds-col">
                            <BarChart
                                heading={"MPA Status Distribution"}
                                data={mpaBarChartData}
                                liClass={getMPAsBarChartSize()}
                                headerClass={"slds-text-title_bold slds-text-color--weak"}
                                contentText={"MPA"}
                            />
                        </div>
                    </div>
                    <h2 className={"slds-text-title_bold slds-text-color--weak slds-m-top--x-small"}> MPAs</h2>
                    <Accordion>
                        {selectedDeployment.operations
                            .filter((operation) => operation.operation_id === selectedOperationID)
                            .map((operation, index) => (
                            <AccordionPanel
                                key={index}
                                expanded={selectedMPA == operation.mpa_id}
                                onTogglePanel={() => {
                                    if (selectedMPA === operation.mpa_id) {
                                        setSelectedMPA("");
                                    } else {
                                        setSelectedMPA(operation.mpa_id);
                                    }
                                }}
                                summary={
                                    <div className="slds-text-wrap" style={{whiteSpace: 'normal'}}>
                                        <ThemedBadge
                                            className={"slds-m-right_medium"}
                                            theme={CONSTANT_GENERIC_DEPLOYMENTS.GET_STATUS_THEME(operation.status, true)}
                                            text={operation.status}
                                        />
                                        {`${operation.mpa_id}`}
                                    </div>
                                }
                                className="slds-m-bottom_small"
                            >
                                <div className="slds-grid slds-box">
                                    <div className="slds-col slds-size_3-of-3 slds-m-horizontal--x-small">
                                        <ul className="slds-list_dotted">
                                            {getDetailsLi("AWS Path", operation.aws_path)}
                                            {getDetailsLi("Operation Type", operation.operation_type)}
                                            {getDetailsLi("SCP ID", operation.scp_id)}
                                            {getDetailsLi("SCP Name", operation.scp_name)}
                                            {getDetailsLi("Is Falcon", `${operation.is_falcon}`)}
                                            {getDetailsLi("Status", operation.status)}
                                            {getDetailsLi("Stagger Level", operation.stagger_level)}
                                            {getDetailsLi("Stagger Stage", operation.stagger_stage)}
                                            {getDetailsLi("Created At", timestampStringToDate(operation.timestamp_of_operation_creation))}
                                            {getDetailsLi("Updated At", timestampStringToDate(operation.current_stagger_stage_completed_timestamp))}
                                        </ul>
                                    </div>
                                </div>
                            </AccordionPanel>
                        ))}
                    </Accordion>

                </>
            }
        </Card>
    </>
}

export default SCPMPAsCard;
