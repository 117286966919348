import {BarChartRow, PrepareBarChartData} from "../components/bar_chart/bar_chart";
import {CONSTANT_GENERIC_DEPLOYMENTS, CONSTANT_STACKSETS_DRIFTS} from "../../constants/themes";

export class GenericServiceSummaryItem {
    status: string;
    count: number;

    constructor(status: string, count: number) {
        this.status = status;
        this.count = count;
    }

    // fromJSON function to convert a raw JSON object to an instance of GenericServiceSummary
    static fromJSON(json: any): GenericServiceSummaryItem {
        // If the data is invalid, return a default GenericServiceSummary object with empty values
        if (typeof json.status !== 'string' || typeof json.count !== 'number') {
            console.warn("Invalid data for GenericServiceSummary:", json);
            return new GenericServiceSummaryItem('', 0);
        }
        return new GenericServiceSummaryItem(json.status, json.count);
    }
}

export class GenericServiceSummary {
    summaries: GenericServiceSummaryItem[];

    constructor(summaries: GenericServiceSummaryItem[]) {
        this.summaries = summaries;
    }

    // fromJSON function to convert a raw JSON object (with summaries array) to an instance of GenericServiceSummaries
    static fromJSON(json: any): GenericServiceSummary {
        // If the data is invalid, return a default GenericServiceSummaries object with an empty array
        if (!json || !Array.isArray(json.summaries)) {
            return new GenericServiceSummary([]);
        }

        // Map through each item in the summaries array and convert it to a GenericServiceSummary instance
        const summaries = json.summaries.map((item: any) => GenericServiceSummaryItem.fromJSON(item));

        // Return a new instance with the valid (or default) summaries
        return new GenericServiceSummary(summaries);
    }

    generateBarChartData(theme: (status: string) => string = CONSTANT_GENERIC_DEPLOYMENTS.GET_STATUS_THEME): BarChartRow[] {
        if (!this.summaries) return [];
        const statusCounts = new Map<string, number>();
        // Count statuses
        this.summaries.forEach(item => {
            const status = item.status || "Unknown";
            const count = item.count ?? 0; // Default to 0 if undefined
            statusCounts.set(status, (statusCounts.get(status) ?? 0) + count);
        });
        return PrepareBarChartData(statusCounts, theme)
    }
}
