import {MainSCPDeploymentApiService} from "./api_repository";
import {SCPDeploymentApiService} from "./service";
import {MockSCPDeploymentApiService} from "./mock_api_repository";
import {API_MOCK_STATUS} from "../../environment/environment";
export class SCPDeploymentAPIServiceFactory {
    static getService(): SCPDeploymentApiService {
        if (API_MOCK_STATUS.isSCPMockEnabled()) {
            return new MockSCPDeploymentApiService();
        } else {
            return new MainSCPDeploymentApiService();
        }
    }
}
