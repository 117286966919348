import React from 'react';
import {Accordion, AccordionPanel, Card, Icon} from "@salesforce/design-system-react";
import BarChart, {BarChartRow} from "../../../../utils/components/bar_chart/bar_chart";
import ThemedBadge from "../../../../utils/components/tag/tag";
import {StackSetsDeployment} from "../../../../utils/types/deployments/stacksets/deployment";
import {StackSetOperation} from "../../../../utils/types/deployments/stacksets/operation";

const ChangesCard: React.FC<{
    selectedCommit: StackSetsDeployment | null;
    selectedStackSet: StackSetOperation | null;
    stackSetsBarChartData: BarChartRow[];
    handleSelectStackSet: (operation: StackSetOperation) => void;
    getStackSetsBarChartSize: () => string;
    getStaggersCard: () => React.ReactNode;
    CONSTANT_GENERIC_DEPLOYMENTS: any;
}> = ({
          selectedCommit,
          selectedStackSet,
          stackSetsBarChartData,
          handleSelectStackSet,
          getStackSetsBarChartSize,
          getStaggersCard,
          CONSTANT_GENERIC_DEPLOYMENTS
      }) => {
    return (
        <>
            {selectedCommit && (
                <Card
                    heading="Changes"
                    icon={<Icon category="standard" name="code_set" size="small" />}
                    className="slds-m-right--small slds-m-left_small slds-m-bottom_small"
                    bodyClassName="slds-p-left--large slds-p-right--large slds-p-bottom--large slds-p-top--x-small"
                    style={{ minHeight: "50vh" }}
                >
                    {selectedCommit.changes.length > 0 ? (
                        <div className="slds-grid slds-wrap">
                            <div className="slds-col">
                                <BarChart
                                    heading="StackSet Status Distribution"
                                    data={stackSetsBarChartData}
                                    liClass={getStackSetsBarChartSize()}
                                    headerClass="slds-text-title_bold slds-text-color--weak"
                                    contentText="StackSet"
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="slds-grid slds-wrap">
                            <div className="slds-col">
                                <p>No Stacksets were changed in this commit</p>
                            </div>
                        </div>
                    )}

                    {selectedCommit.changes.length > 0 && (
                        <h2 className="slds-text-title_bold slds-text-color--weak slds-m-top--x-small">
                            StackSets [{selectedCommit.changes.length}]
                        </h2>
                    )}

                    <Accordion>
                        {selectedCommit.changes.length > 0 ? (
                            selectedCommit.changes.map((operation: any, index: number) => (
                                <AccordionPanel
                                    key={index}
                                    expanded={selectedStackSet?.id === operation.id}
                                    onTogglePanel={() => handleSelectStackSet(operation)}
                                    summary={
                                        <div className="slds-text-wrap" style={{ whiteSpace: 'normal' }}>
                                            <ThemedBadge
                                                className="slds-m-right_medium"
                                                theme={CONSTANT_GENERIC_DEPLOYMENTS.GET_STATUS_THEME(operation.stackset_status, true)}
                                                text={operation.stackset_status}
                                            />
                                            {operation.stackset_id}
                                        </div>
                                    }
                                    className="slds-m-bottom_small"
                                >
                                    <div className="slds-grid slds-box slds-m-left--large">
                                        <ul>
                                            {getStaggersCard()}
                                        </ul>
                                    </div>
                                </AccordionPanel>
                            ))
                        ) : (
                            <div className="slds-grid slds-wrap">
                                <div className="slds-col">
                                    <p>No Stagger Groups</p>
                                </div>
                            </div>
                        )}
                    </Accordion>
                </Card>
            )}
        </>
    );
};

export default ChangesCard;
