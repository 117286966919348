import {
    AccountStackSetDriftInfo,
    DriftDetails,
    DriftedAccountRequest

} from "../../utils/types/stackSetDrift";
import {DriftDetectionApiService} from "./service";
import {GenericServiceSummary} from "../../utils/types/genericServiceSummaryItem";

function getRandomInt(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

function randomDate(start: Date, end: Date): Date {
    return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()))
}

const dummyStackSetNames: string[] = ["commonIAM", "scopeSCPServiceManagedTest", "scopeSAIRole", "nonFalconMPA", "falconMPA", "commonIAMNonFalcon", "cloudtrail"]
const dummyDriftStatus: string[] = ["DRIFTED", "NOT_CHECKED"]  // "IN_SYNC" not taken
const stackSetInstanceStatus: string[] = ["INOPERABLE", "OUTDATED", "CURRENT"]
const statusReasons: string[] = [
    "Target account was suspended",
    "Target account was deleted",
    "Target account was not reachable",
    "Target account was not checked",
    "Target account was not found",
    "Target account was not accessible",
    "Target account was not operational",
    "Target account was not in sync",
    "Target account was not in operation",
    "Target account was not in compliance",
    "Target account was not in scope",
    "Target account was not in the list",
    "Target account was not in the database",
    "Target account was not in the system",
    "Target account was not in the stack",
    "Target account was not in the cloud",
    "Target account was not in the region",
    "Target account was not in the environment",
    "Target account was not in the group",
    "Target account was not in the organization",
    "Target account was not in the project",
    "Target account was not in the service",
    "Target account was not in the application",
    "Target account was not in the platform",
    "Target account was not in the network",
    "Target account was not in the security",
    "Target account was not in the compliance",
    "Target account was not in the governance",
    "Target account was not in the management",
    "Target account was not in the monitoring",
    "Target account was not in the logging",
    "Target account was not in the alerting",
    "Target account was not in the notification",
    "Target account was not in the dashboard",
    "Target account was not in the report",
    "Target account was not in the analysis",
    "Target account was not in the audit",
    "Target account was not in the review",
    "Target account was not in the assessment",
    "Target account was not in the evaluation",
    "Target account was not in the validation",
    "Target account was not in the verification",
    "Target account was not in the testing",
    "Target account was not in the deployment",
    "Target account was not in the release",
    "Target account was not in the build",
    "Target account was not in the development",
    "Target account was not in the staging",
    "Target account was not in the production",
    "Target account was not in the disaster recovery",
    "Target account was not in the backup",
    "Target account was not in the restore",
    "Target account was not in the archive",
    "Target account was not in the retention",
    "Target account was not in the compliance",
    "Target account was not in the governance",
]

export class MockDriftDetectionApiService implements DriftDetectionApiService {
    async getStackSetDrift(filter: DriftedAccountRequest): Promise<AccountStackSetDriftInfo[]> {
        return new Promise<AccountStackSetDriftInfo[]>(resolve => {
            setTimeout(() => {
                const accounts: AccountStackSetDriftInfo[] = [];
                for (let i = 1; i <= 1000; i++) {
                    let randomAwsAccountId = accounts[getRandomInt(1, 80)]?.account_id || `${Math.floor(Math.random() * 2000000000000)}`; // 12 digit number

                    const randomStackSetName = dummyStackSetNames[getRandomInt(0, 6)];
                    const randomDriftStatus = dummyDriftStatus[getRandomInt(0, 1)];
                    const drift_details: DriftDetails[] = []
                    if (randomDriftStatus === "DRIFTED") {
                        drift_details.push({
                            LogicalResourceId: "SSMStartReadOnlyInteractiveCommand",
                            ResourceType: "AWS::SSM::Document",
                            StackId: "arn:aws:cloudformation:us-east-1:050694043254:stack/StackSet-commonIAM-2aded79c-41a1-4027-81a3-f2ba765ee70d/dc17bb40-c9c0-11ed-aded-0a4a6de85255",
                            PhysicalResourceId: "SSM-StartReadOnlyInteractiveCommand",
                            StackResourceDriftStatus: "MODIFIED",
                            PropertyDifferences: [
                                {
                                    PropertyPath: "/Content",
                                    ExpectedValue: `{"description":"Document to run single interactive command on an instance.", "schemaVersion":"2.2", "parameters": {"commands": {"type": "StringList"}}}`,
                                    ActualValue: "null",
                                    DifferenceType: "REMOVE"
                                }
                            ],

                        })
                    }

                    let randomFalsePositive: boolean = false
                    if (randomDriftStatus === "DRIFTED") {
                        randomFalsePositive = Math.random() < 0.6
                    }

                    const randomStackInstanceStatus = stackSetInstanceStatus[getRandomInt(0, 2)]

                    let randomStatusReason = ''
                    if (randomStackInstanceStatus !== "CURRENT") {
                        randomStatusReason = statusReasons[getRandomInt(0, 25)]
                    }

                    const randomDriftCheckTime = randomDate(new Date(2024, 4, 1), new Date()).getTime()

                    // Apply Filters
                    if (filter.account_id && filter.account_id !== randomAwsAccountId) {
                        continue
                    } else if (i % 20 === 0) {
                        randomAwsAccountId = filter.account_id
                    }

                    if (filter.stackset_name && filter.stackset_name !== randomStackSetName) {
                        continue
                    }

                    if (filter.from_time && filter.from_time > new Date(randomDriftCheckTime)) {
                        continue
                    }

                    if (filter.to_time && filter.to_time < new Date(randomDriftCheckTime)) {
                        continue
                    }

                    let accountStackSetDriftInfo: AccountStackSetDriftInfo = {
                        index: i,
                        account_id: randomAwsAccountId,
                        stackset_name: randomStackSetName,
                        drift_status: randomDriftStatus,
                        false_positive: randomFalsePositive,
                        instance_status: randomStackInstanceStatus,
                        status_reason: randomStatusReason,
                        drift_check_timestamp: Math.round(randomDriftCheckTime / 1000),
                        drift_details: drift_details,
                    }


                    accounts.push(accountStackSetDriftInfo);
                }
                resolve(accounts);
            }, 2000);
        });
    }
    async getStackSetDriftSummary(): Promise<GenericServiceSummary> {
        return new Promise<any>(resolve => {
            setTimeout(() => {
                const summary = GenericServiceSummary.fromJSON({
                    "summaries": [
                        {
                            "status": "Completed",
                            "count": 5
                        }
                    ]
                })
                resolve(summary);
            }, 1000);
        });
    }
}