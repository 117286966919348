import React from 'react';
import { Spinner } from '@salesforce/design-system-react';

interface CustomSpinnerOverlayProps {
    size: 'xx-small' | 'x-small' | 'small' | 'medium' | 'large';
    variant?: 'base' | 'brand' | 'inverse';
}

const CustomSpinnerOverlay: React.FC<CustomSpinnerOverlayProps> = ({ size, variant }) => {
    return (
        <div className="slds-is-relative slds-align_absolute-center">
            <div className="slds-spinner_container slds-is-relative">
                <div role="status" className="slds-spinner">
                    <Spinner size={size} variant={variant ? variant : "brand"} />
                    <span className="slds-assistive-text">Processing...</span>
                </div>
            </div>
        </div>
    );
};

export default CustomSpinnerOverlay;
