import React, {useCallback, useEffect, useMemo, useState} from "react";
import {
    DataTableColumn,
    DataTable,
    Button,
    DataTableCell,
    Card,
    ButtonGroup, Combobox, Accordion, AccordionPanel, Input
} from "@salesforce/design-system-react";
import { ToastState } from "../../../utils/types/toast";
import { PCSKAccountClosureItem } from "../../../utils/types/accountClosure";
import { TOAST_MESSAGE } from "../../../constants/toastMessages";
import { CustomStatusCellProps } from "../../../utils/components/table_cell/auto_break_cell"
import { CustomHeightPageSpinner } from "../../../utils/components/spinner/page_spinner_overlay"
import AccountClosureApprovalModal from "../../../modals/AccountClosureApprovalModal"
import AccountClosureMoreDetails from "../../../modals/AccountClosureMoreDetails";
import CustomBreakDataTableCell from "../../../utils/components/table_cell/custom_break_cell";
import { AccountClosureApiServiceFactory } from "../../../services/account_closure/factory";
import {CONSTANT_ACCOUNT_CLOSURE} from "../../../constants/themes";
import {DownloadCSV} from "../../../utils/download/csv";

type AccountClosureProps = {
    action?: (type: string) => void;
    isActive: boolean;
    setActiveTab: (tab: string) => void;
    setActiveSubTab: (tabIndex: number) => void;
    toast: ToastState;
    setToast: React.Dispatch<React.SetStateAction<ToastState>>;
    requestedURL: URL
};

type barChartRow = { label: string, value: number , percentage: string, class: string }

const AccountClosure: React.FC<AccountClosureProps> = ({isActive, toast, setToast, requestedURL}) => {

    // stateful on page
    const [data, setData] = useState<PCSKAccountClosureItem[]>([]);
    const [filteredData, setFilteredData] = useState<PCSKAccountClosureItem[]>([]);
    const [selectedRow, setSelectedRow] = useState<PCSKAccountClosureItem | null>(null);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isAccountListLoading, setIsAccountListLoading] = useState<boolean>(false);
    const [isApprovalInProgress, setIsApprovalInProgress] = useState<boolean>(false);
    const [isMoreDetailsModalOpen, setIsMoreDetailsModalOpen] = useState<boolean>(false);

    // search filters
    const [accountSearchTerm, setAccountSearchTerm] = useState<string>('');
    const [mpaSearchTerm, setMPASearchTerm] = useState<string>('');
    const [fiSearchTerm, setFISearchTerm] = useState<string>('');
    const [fdSearchTerm, setFDSearchTerm] = useState<string>('');
    const [teamSearchTerm, setTeamSearchTerm] = useState<string>('');
    const [statusFilterValue, setStatusFilterValue] = useState<string>('');
    const [activeFalconOrNonFalconButton, setActiveFalconOrNonFalconButton] = useState<string | null>(null);
    const dataTableCellSize: number = 20;

    // pagination controls
    const [currentPage, setCurrentPage] = useState<number>(1);
    const rowsPerPage: number = 50;
    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const currentPageData = filteredData.slice(startIndex, endIndex);
    const totalPages = Math.ceil(filteredData.length / rowsPerPage);

    // panel controls
    const [activePanels, setActivePanels] = useState<string[]>([]);

    // charts
    const [barChartData, setBarChartData] = useState<barChartRow[]>([])

    // API repository
    const closureAPIService = AccountClosureApiServiceFactory.getService();

    // Deep link filter statuses
    const closureStatusOptions = [
        { value: '', label: 'All' },
        { value: 'pending_my_approval', label: 'Pending My Approval' },
        { value: 'waiting_approvals', label: 'Waiting Approvals' },
        { value: 'ready_for_closure', label: 'Ready For Closure' },
        { value: 'closed', label: 'Closed' },
        { value: 'expired', label: 'Expired' },
        { value: 'failed', label: 'Failed' },
    ];

    // Custom cell that displays the action buttons
    const CustomClosureActionsCell: React.FC<CustomStatusCellProps> = ({ children, item, ...props }) => {
        return (
            <td className='slds-text-align_left'>
                {item?.can_approve && (
                    <Button
                        label="Approve"
                        className='slds-button slds-button_brand slds-m-bottom--xx-small slds-m-left--xxx-small'
                        onClick={() => handleApproveButtonClick(item)}
                    />
                )}
                <Button
                    iconCategory="utility"
                    iconName="threedots"
                    title="More Details"
                    iconSize="medium"
                    onClick={() => {
                        setSelectedRow(item ?? null);
                        setIsMoreDetailsModalOpen(!isMoreDetailsModalOpen)
                    }}
                    disabled={isAccountListLoading}
                    className="slds-button_icon slds-button_small"
                />
            </td>
        );
    };
    CustomClosureActionsCell.displayName = DataTableCell.displayName;

    // Handle page re-loads and tab switches
    useEffect(() => {
        if (isActive) {
            clearFilters()
            setIsMoreDetailsModalOpen(false)

            fetchClosureAccounts()
        }
    }, [isActive]);

    // Handle Charts re-render
    useEffect(() => {
        const statusCounts = new Map<string, number>();
        // Count statuses
        filteredData.forEach(item => {
            const status = item.status;
            statusCounts.set(status, (statusCounts.get(status) ?? 0) + 1);
        });

        // Build bar chart data
        const barChartData = Array.from(statusCounts.entries()).map(([status, count]) => {
            const percentage = (count / filteredData.length) * 100;
            const formattedPercentage = percentage.toFixed(Number.isInteger(percentage) ? 0 : 2);
            return {
                label: status || "None",
                value: count,
                percentage: formattedPercentage,
                class: CONSTANT_ACCOUNT_CLOSURE.GET_STATUS_THEME(status),
            };
        });
        setBarChartData(barChartData)
    }, [filteredData]);

    // Handle filter changes
    useEffect(() => {
        filterAccounts()
    }, [accountSearchTerm, teamSearchTerm, fdSearchTerm, fiSearchTerm, teamSearchTerm, statusFilterValue,
        activeFalconOrNonFalconButton, mpaSearchTerm, !isAccountListLoading]);

    const handlePreviousPage = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };

    const handleNextPage = () => {
        setCurrentPage(prevPage => Math.min(prevPage + 1, Math.ceil(filteredData.length / rowsPerPage)));
    };

    const handleTogglePanel = (panelId: string) => {
        setActivePanels((prevPanels) => {
            if (prevPanels.includes(panelId)) {
                return prevPanels.filter((id) => id !== panelId);
            } else {
                return [...prevPanels, panelId];
            }
        });
    };

    const handleConfirmApproval = () => {
        setIsApprovalInProgress(true);
        if (selectedRow == null) {
            return
        }
        let accID = selectedRow?.aws_account_id
        closureAPIService.approveAccountClosure(accID).then((response: PCSKAccountClosureItem) => {
            let accID = selectedRow?.aws_account_id || ""
            toast.isVisible = false
            setToast({
                isVisible: true,
                message: {
                    details: TOAST_MESSAGE.ACCOUNT_CLOSURE_APPROVAL_SUCCESS(accID),
                    heading: "Success",
                },
                variant: "success"
            });
            for (let i = 0; i< data.length; i++) {
                if(data[i].aws_account_id == accID) {
                    data[i] = response
                }
            }
            for (let i = 0; i < filteredData.length; i++) {
                if(filteredData[i].aws_account_id == accID) {
                    filteredData[i] = response
                }
            }
            setData(data);
            setFilteredData(filteredData)
        }).catch((message) => {
            toast.isVisible = false
            setToast({
                isVisible: true,
                message: {
                    details: TOAST_MESSAGE.ACCOUNT_CLOSURE_APPROVAL_FAILED(accID, message),
                    heading: "Error",
                },
                variant: "error"
            });
        }).finally(() => {
            setIsApprovalInProgress(false)
            setIsModalOpen(false);
            filterAccounts()
        });
    };

    const fetchClosureAccounts = (isRefresh = false) => {
        setIsAccountListLoading(true)
        setIsModalOpen(false); // close modal on fresh account reloads
        let updatedData = data
        closureAPIService.getAccountClosures().then((response: PCSKAccountClosureItem[]) => {
            for (let i = 0 ; i < response.length; i++) {
                response[i].index = i + 1
            }
            updatedData = response
        }).catch((message) => {
            setToast({
                isVisible: true,
                message: {
                    details: TOAST_MESSAGE.FAILED_LOADING_ACCOUNT_CLOSURES(message),
                    heading: "",
                },
                variant: "error"
            });
        }).finally(() => {
            setData(updatedData);
            setFilteredData(updatedData)
            if (requestedURL) {
                const searchParams = new URLSearchParams(requestedURL.search);
                let filter = (searchParams.get("filter") || "").toString().toLowerCase()
                if (!isRefresh && requestedURL.pathname.startsWith('/account-closure')) {
                    if (filter.length == 0) {
                        setStatusFilterValue('pending_my_approval');
                        handleTogglePanel('panel1')
                    } else {
                        const filterValue = closureStatusOptions.find(option => option.value.toLowerCase().includes(filter));
                        if (filterValue) {
                            setStatusFilterValue(filterValue.value);
                            if (filterValue.value == "pending_my_approval") {
                                handleTogglePanel('panel1')
                            }
                        }
                    }

                }
                setCurrentPage(1)
            }
            setIsAccountListLoading(false)
        });
    }

    const handleFalconTypeButtonClick = (buttonType: string) => {
        if (activeFalconOrNonFalconButton == buttonType) {
            setActiveFalconOrNonFalconButton('')
        } else {
            setActiveFalconOrNonFalconButton(buttonType)
        }
    };

    const handleApproveButtonClick = (item: PCSKAccountClosureItem | undefined) => {
        if (item) {
            setSelectedRow(item);
            setIsModalOpen(true);
        }
    };
    const handleRefreshClick = () => {
        fetchClosureAccounts(true)
        setIsMoreDetailsModalOpen(false)
    }

    const clearFilters = () => {
        setAccountSearchTerm('');
        setMPASearchTerm('');
        setFISearchTerm('');
        setFDSearchTerm('');
        setTeamSearchTerm('');
        setStatusFilterValue('');
        setActiveFalconOrNonFalconButton(null);
    }

    const handleResetFiltersClick = () => {
        clearFilters()
    }

    const handleStatusDropdownChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const filterValue = event.target.value;
        setStatusFilterValue(filterValue);
    };

    const handleDownloadClick = () => {
        // Convert JSON to CSV
        const convertToCSV = (jsonArray: PCSKAccountClosureItem[]): string => {
            if (!jsonArray || jsonArray.length === 0) return '';
            const headers = jsonArray[0].getCSVHeaders()
            const rows = jsonArray.map(item => {
                return item.getCSVData()
            });
            // Concatenate headers and rows with line breaks
            return [headers].concat(rows).join('\n');
        };

        DownloadCSV(convertToCSV(filteredData), "pcsk_account_closure_results")
    };

    const filterAccounts = () => {
        let dataCopy = data
        if (accountSearchTerm !== '') {
            dataCopy = dataCopy.filter(account =>
                account.display_account.toLowerCase().includes(accountSearchTerm.toLowerCase()) ||
                account.email.toLowerCase().includes(accountSearchTerm.toLowerCase()));
        }
        if (fiSearchTerm !== '') {
            dataCopy = dataCopy.filter(account => account.fi.toLowerCase().includes(fiSearchTerm.toLowerCase()));
        }
        if (fdSearchTerm !== '') {
            dataCopy = dataCopy.filter(account => account.fd.toLowerCase().includes(fdSearchTerm.toLowerCase()));
        }
        if (teamSearchTerm !== '') {
            dataCopy = dataCopy.filter(account => account.team.toLowerCase().includes(teamSearchTerm.toLowerCase()));
        }
        if (mpaSearchTerm !== '') {
            dataCopy = dataCopy.filter(account => account.org_account_id.toLowerCase().includes(mpaSearchTerm.toLowerCase()));
        }

        // Filter by filter value (if any)
        if ( statusFilterValue !== '') {
            if (statusFilterValue === 'waiting_approvals') {
                dataCopy = dataCopy.filter(account => account.isWaitingApprovals());
            } else if (statusFilterValue === 'ready_for_closure') {
                dataCopy = dataCopy.filter(account => account.isReadyForClosure());
            } else if (statusFilterValue === 'pending_my_approval') {
                dataCopy = dataCopy.filter(account => account.can_approve);
            } else {
                dataCopy = dataCopy.filter(account => account.status.toLowerCase() === statusFilterValue.toLowerCase());
            }
        }

        // Falcon accounts filter
        if (activeFalconOrNonFalconButton === 'falcon') {
            dataCopy = dataCopy.filter(account => account.is_falcon);
        }
        if (activeFalconOrNonFalconButton === 'non-falcon') {
            dataCopy = dataCopy.filter(account => !account.is_falcon);
        }

        dataCopy.sort((a, b) => b.request_timestamp - a.request_timestamp);
        for (let i = 0 ; i < dataCopy.length; i++) {
            dataCopy[i].index = i + 1
        }
        setFilteredData(dataCopy)
        setCurrentPage(1)
    }

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchTerm = event.target.value;
        if (event.target.id == "searchAccountInput") {
            setAccountSearchTerm(searchTerm)
        }
        if (event.target.id == "searchMPAInput") {
            setMPASearchTerm(searchTerm)
        }
        if (event.target.id == "searchFIInput") {
            setFISearchTerm(searchTerm)
        }
        if (event.target.id == "searchFDInput") {
            setFDSearchTerm(searchTerm)
        }
        if (event.target.id == "searchTeamInput") {
            setTeamSearchTerm(searchTerm)
        }
    };

    const renderBarChart = () => (
        <div className="slds-p-vertical_large">
            <h2 className="slds-text-title_caps">Closure Status Distribution</h2>
            <ul className="slds-grid slds-wrap slds-grid_pull-padded">
                {barChartData.map((data, index) => (
                    <li key={index} className="slds-p-horizontal_small slds-size_1-of-5">
                        <div className="slds-box--small">
                            <div className="slds-text-title">{data.label}</div>
                            <span>{data.percentage}%</span>
                            <div className="slds-progress-bar">
                        <span
                            // slds-progress-bar__value_success
                            className={`slds-progress-bar__value ${data.class}`}
                            style={{width: `${data.percentage}%`}}
                        >
                            <span className="slds-assistive-text">{data.value}% Complete</span>
                        </span>
                            </div>
                            <span>{data.value} Account{data.value > 1 && "s"}</span>
                        </div>
                    </li>
                ))}
            </ul>
        </div>

    );

    return <>
        <div className="slds-m-bottom_xx-large slds-is-relative slds-size_1-of-1" style={{minHeight: "80vh"}}>
            <div className="slds-m-bottom--x-small slds-size_8-of-8 slds-color__background_gray-3">
                <Accordion>
                    <AccordionPanel
                        expanded={activePanels.includes('panel1')}
                        onTogglePanel={() => handleTogglePanel('panel1')}
                        summary="Approval Guidelines"
                        className={"slds-box"}
                    >
                        <ul className="slds-list_dotted">
                            <li>
                                The table below presents aws accounts currently in the PCSK queue for closure.
                            </li>
                            <li>
                                To confirm the closures, please click on the 'Approve' button. Once approved, no
                                further action is required from your side.
                            </li>
                            <li>
                                The closure process awaits approvals until it reaches the specified 'Approval
                                Expiry' deadline, as outlined in the table. Post expiry date the account is removed
                                from PCSK closure queue.
                            </li>
                        </ul>
                    </AccordionPanel>
                </Accordion>
            </div>
            <Card
                heading={
                    <div className="slds-grid slds-grid_align-spread slds-grid_vertical-align-center">
                        <span>Filter Results</span>
                        <ButtonGroup>
                            <Button
                                iconCategory="utility"
                                label={<>&nbsp; Reset</>}
                                iconName="skip_back"
                                title="Reset Filters"
                                iconSize="medium"
                                onClick={handleResetFiltersClick}
                                disabled={isAccountListLoading}
                                variant={"neutral"}
                                className="slds-button_icon slds-button_small slds-text-body_regular"
                            />
                        </ButtonGroup>
                    </div>
                }
                className=""
                bodyClassName="slds-p-right_medium slds-p-left_medium slds-p-bottom_medium"
            >
                <div className="slds-grid slds-gutters slds-m-bottom--x-small">
                    <div className="slds-col slds-size_2-of-5">
                        <label className="slds-form-element__label" htmlFor="searchInput">Search:</label>
                        <input
                            id="searchAccountInput"
                            type="text"
                            title="account_id, name or email..."
                            className="slds-input"
                            placeholder="account_id, name or email..."
                            value={accountSearchTerm}
                            onChange={(e) => handleSearchChange(e)}
                            disabled={isAccountListLoading}
                        />
                    </div>
                    <div className="slds-col slds-size_1-of-5">
                        <label className="slds-form-element__label" htmlFor="searchInput">Org Account ID:</label>
                        <input
                            id="searchMPAInput"
                            type="text"
                            title="filter by mpa_id"
                            className="slds-input"
                            placeholder="org_account_id"
                            value={mpaSearchTerm}
                            onChange={handleSearchChange}
                            disabled={isAccountListLoading}
                        />
                    </div>
                    <div className="slds-col slds-size_1-of-5">
                        <label className="slds-form-element__label" htmlFor="searchInput">Falcon Instance ID:</label>
                        <input
                            id="searchFIInput"
                            type="text"
                            title="filter by FI"
                            className="slds-input"
                            placeholder="fi_id"
                            value={fiSearchTerm}
                            onChange={handleSearchChange}
                            disabled={isAccountListLoading}
                        />
                    </div>
                    <div className="slds-col slds-size_1-of-5">
                        <label className="slds-form-element__label" htmlFor="searchInput">Functional Domain ID:</label>
                        <input
                            id="searchFDInput"
                            type="text"
                            title="filter by FD"
                            className="slds-input"
                            placeholder="fd_id"
                            value={fdSearchTerm}
                            onChange={handleSearchChange}
                            disabled={isAccountListLoading}
                        />
                    </div>
                </div>

                <div className="slds-grid slds-gutters slds-m-bottom--x-small">
                    <div className="slds-col slds-size_1-of-5">
                        <label className="slds-form-element__label" htmlFor="searchInput">Service Team:</label>
                        <input
                            id="searchTeamInput"
                            type="text"
                            title="team"
                            className="slds-input"
                            placeholder="service_team"
                            value={teamSearchTerm}
                            onChange={handleSearchChange}
                            disabled={isAccountListLoading}
                        />
                    </div>
                    <div className="slds-col slds-large-size_1-of-5">
                        <label className="slds-form-element__label" htmlFor="filterSelect">Closure Status:</label>
                        <select
                            className="slds-select"
                            value={statusFilterValue}
                            onChange={handleStatusDropdownChange}
                            disabled={isAccountListLoading}
                        >
                            {closureStatusOptions.map(option => (
                                <option key={option.value} value={option.value}>{option.label}</option>
                            ))}
                        </select>
                    </div>
                    <div className="slds-col slds-size_1-of-8">
                        <div className="slds-form-element">
                            <label className="slds-form-element__label" htmlFor="searchInput">Falcon Status:</label>
                            <div className="slds-form-element__control">
                                <ButtonGroup>
                                    <Button
                                        label="Falcon"
                                        title="Falcon"
                                        disabled={isAccountListLoading}
                                        className="slds-button_icon slds-button_small slds-text-color--default"
                                        variant={activeFalconOrNonFalconButton === "falcon" ? "brand" : "neutral"}
                                        onClick={() => handleFalconTypeButtonClick("falcon")}
                                    />
                                    <Button
                                        label="Non Falcon"
                                        title="Non Falcon"
                                        disabled={isAccountListLoading}
                                        className="slds-button_icon slds-button_small slds-text-color--default"
                                        variant={activeFalconOrNonFalconButton === "non-falcon" ? "brand" : "neutral"}
                                        onClick={() => handleFalconTypeButtonClick("non-falcon")}
                                    />
                                </ButtonGroup>
                            </div>
                        </div>
                    </div>
                </div>
            </Card>
            {isAccountListLoading ? (
                <CustomHeightPageSpinner size="medium" minHeight="30vh"/>
            ) : filteredData.length > 0 ? (
                <>
                    {/* Bar Chart */}
                    <div className="slds-grid slds-wrap">
                        <div className="slds-col slds-size_1-of-1 slds-m-right_medium">
                            {renderBarChart()}
                        </div>
                    </div>

                    {/* Pagination Info */}
                    <div
                        className="slds-grid slds-grid_vertical-align-center slds-m-top_medium slds-m-right_medium slds-box slds-color__background_gray-3">
                        <span className="slds-col slds-text-color_success slds-text-title_bold">
                            Showing {startIndex + 1} - {Math.min(endIndex, filteredData.length)} of {filteredData.length}
                        </span>
                        <div className="slds-form-element slds-m-right--large">
                            <div className="slds-form-element__control slds-col">
                                <ButtonGroup>
                                    <Button
                                        iconCategory="utility"
                                        iconName="download"
                                        label={<>&nbsp; Download</>}
                                        title="Download Displayed Data"
                                        iconSize="medium"
                                        onClick={handleDownloadClick}
                                        disabled={isAccountListLoading}
                                        variant={"neutral"}
                                        className="slds-button_icon slds-button_small"
                                    />
                                    <Button
                                        iconCategory="utility"
                                        iconName="refresh"
                                        label={<>&nbsp; Refresh</>}
                                        title="Refresh Displayed Data"
                                        iconSize="medium"
                                        onClick={handleRefreshClick}
                                        disabled={isAccountListLoading}
                                        variant={"neutral"}
                                        className="slds-button_icon slds-button_small"
                                    />
                                </ButtonGroup>
                            </div>
                        </div>
                        {!isAccountListLoading && filteredData.length > 0 && (
                            <div className="slds-grid">
                                <div className="slds-col slds-size_1-of-2 slds-align-middle">
                                    <span>
                                        Page {Math.min(currentPage, totalPages)} of {totalPages}
                                    </span>
                                </div>
                                <div className="slds-col slds-size_1-of-2">
                                    <ButtonGroup className="slds-m-left--xxx-small">
                                        <Button label="<" onClick={handlePreviousPage} disabled={currentPage <= 1}/>
                                        <Button label=">" onClick={handleNextPage}
                                                disabled={currentPage === totalPages || totalPages === 0}/>
                                    </ButtonGroup>
                                </div>
                            </div>
                        )}
                    </div>
                    {/* Table */}
                    <div className="slds-col slds-size_8-of-8 slds-wrap slds-m-top_small" style={{width: '99%'}}>
                        <DataTable
                            items={currentPageData}
                            id="account-closures-table"
                            hasCellActions={false}
                            striped={true}
                            columnBordered={true}
                            resizeBehavior="auto"
                        >
                            <DataTableColumn label="Index" property='index'>
                                <CustomBreakDataTableCell size={dataTableCellSize}/>
                            </DataTableColumn>
                            <DataTableColumn label="AWS Account" property='display_account'>
                                <CustomBreakDataTableCell size={dataTableCellSize}/>
                            </DataTableColumn>
                            <DataTableColumn label="Email" property='email'>
                                <CustomBreakDataTableCell size={dataTableCellSize}/>
                            </DataTableColumn>
                            <DataTableColumn label="Org Account ID" property='org_account_id'>
                                <CustomBreakDataTableCell size={dataTableCellSize}/>
                            </DataTableColumn>
                            <DataTableColumn label="Requested on" property='request_date'>
                                <CustomBreakDataTableCell size={dataTableCellSize}/>
                            </DataTableColumn>
                            <DataTableColumn label="Reminders" property='sent_notifications_count'>
                                <CustomBreakDataTableCell td_class={"slds-text-align_center"} suffix={"Sent"} size={dataTableCellSize}/>
                            </DataTableColumn>
                            <DataTableColumn label="Approval Expiry" property='approval_expiry_date'>
                                <CustomBreakDataTableCell size={dataTableCellSize}/>
                            </DataTableColumn>
                            <DataTableColumn label="Approved By" property='approved_by_emails'>
                                <CustomBreakDataTableCell size={dataTableCellSize}/>
                            </DataTableColumn>
                            <DataTableColumn label="#Approvals Pending" property='pending_approvals'>
                                <CustomBreakDataTableCell td_class={"slds-text-align_center"} size={dataTableCellSize}/>
                            </DataTableColumn>
                            <DataTableColumn label="Status" property='status'>
                                <CustomBreakDataTableCell size={dataTableCellSize}/>
                            </DataTableColumn>
                            <DataTableColumn label="Metadata" property='can_approve'>
                                <CustomClosureActionsCell/>
                            </DataTableColumn>
                        </DataTable>
                    </div>
                </>
            ) : (
                <>
                    <div
                        className="slds-m-top_xx-large slds-size_1-of-1 slds-grid slds-grid_vertical-align-center slds-grid_align-center slds-grid_vertical">
                        <div className="slds-align_absolute-center">
                            <strong>No Accounts Found</strong>
                        </div>
                    </div>
                </>
            )}

            {/* Approval Modal */}
            <AccountClosureApprovalModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                isApprovalInProgress={isApprovalInProgress}
                handleConfirmApproval={handleConfirmApproval}
                awsAccountID={selectedRow == null ? "" : selectedRow?.aws_account_id}
                awsAccountName={selectedRow == null ? "" : selectedRow?.name}
                awsAccountEmail={selectedRow == null ? "" : selectedRow?.email}
            />

            {/* Metadata Modal */}
            {isMoreDetailsModalOpen &&
                <AccountClosureMoreDetails
                    isModalOpen={isMoreDetailsModalOpen}
                    setIsModalOpen={setIsMoreDetailsModalOpen}
                    data={selectedRow}
                />
            }
        </div>
    </>;
};
export default AccountClosure;
