import React from 'react';
import {Icon, Spinner} from '@salesforce/design-system-react';
import CustomSpinnerOverlay from "./custom_spinner_overlay";

interface IconSpinnerOverlayProps {
    size: 'xx-small' | 'x-small' | 'small' | 'medium' | 'large';
    loading: boolean;
    onClick: () => void;
}

const IconSpinnerOverlay: React.FC<IconSpinnerOverlayProps> = ({ size,
                                                               loading,
                                                               onClick}) => {
    return (
        <button
            className={`${loading ? 'slds-button slds-m-right--x-small' : 'slds-button_icon-border-filled'}`}
            title="Refresh"
            onClick={onClick}
            disabled={loading}
        >
            <Icon
                category="utility"
                name={loading ? "sync" : "refresh"}
                size="x-small"
            />
            <span className="slds-assistive-text">Refresh</span>
        </button>
    );
};

export default IconSpinnerOverlay;
