
export class SCPDeployment {
    commit_metadata: {
        commit_id: string;
        commit_title: string;
        author_email: string;
    };
    operations: SCPOperation[];

    constructor(data: {
        commit_metadata: {
            commit_id: string;
            commit_title: string;
            author_email: string;
        };
        operations: SCPOperation[];
    }) {
        this.commit_metadata = {
            commit_id: data.commit_metadata.commit_id,
            commit_title: data.commit_metadata.commit_title,
            author_email: data.commit_metadata.author_email,
        };
        this.operations = data.operations.map((operation) => SCPOperation.fromJSON(operation));
    }

    static GroupByCommit(operations: SCPOperation[]): SCPDeployment[] {
        // Group operations by commit_id
        const grouped = operations.reduce((acc, operation) => {
            const commitId = operation.commit_id;
            if (!acc[commitId]) {
                acc[commitId] = [];
            }
            acc[commitId].push(operation);
            return acc;
        }, {} as Record<string, SCPOperation[]>);

        // Create SCPDeployment instances
        const deployments: SCPDeployment[] = Object.entries(grouped).map(([commit_id, ops]) => {
            let { commit_title, author_email } = ops[0];
            const commit_status = ops[0].status; // You can assign commit_status based on any logic
            commit_title = commit_title && commit_title.length > 0 ? commit_title : "Unknown Commit"
            return new SCPDeployment({
                commit_metadata: {
                    commit_id,
                    commit_title,
                    author_email,
                },
                operations: ops,
            });
        });

        return deployments;
    }

    // Collect all unique operation_id values from operations
    getUniqueOperationIDs(): string[] {
        const uniqueOpIDs = new Set<string>();
        this.operations.forEach((operation) => uniqueOpIDs.add(operation.operation_id));
        return Array.from(uniqueOpIDs);
    }


    // Collect all unique mpa_id values from operations
    getMPAsForOperationID(operationID: string): string[] {
        const uniqueMPAs = new Set<string>();
        this.operations.forEach((operation) => operation.operation_id == operationID && uniqueMPAs.add(operation.mpa_id));
        return Array.from(uniqueMPAs);
    }

    getOperationStatusForMPA(operationID: string, mpa: string): string {
        const operation = this.operations.find((op) => op.operation_id === operationID && op.mpa_id === mpa);
        return operation ? operation.status : "";
    }

    // Get the minimum timestamp_of_operation_creation for a specific operationID
    getCreatedAtForOperation(operationID: string): string {
        const timestamps = this.operations
            .filter((operation) => operation.operation_id === operationID)
            .map((operation) => operation.timestamp_of_operation_creation);

        if (timestamps.length === 0) return "";
        return timestamps.reduce((min, timestamp) => (timestamp < min ? timestamp : min), timestamps[0]);
    }

    // Get the maximum current_stagger_stage_completed_timestamp for a specific operationID
    getMaxStaggerTimestampForOperation(operationID: string): string {
        const timestamps = this.operations
            .filter((operation) => operation.operation_id === operationID)
            .map((operation) => operation.current_stagger_stage_completed_timestamp);

        if (timestamps.length === 0) return "";
        return timestamps.reduce((max, timestamp) => (timestamp > max ? timestamp : max), timestamps[0]);
    }

    getUniqueOperationTypes(operationID: string): string[] {
        const operationTypes = this.operations
            .filter((operation) => operation.operation_id === operationID)
            .map((operation) => operation.operation_type);

        // Return unique operation types
        return Array.from(new Set(operationTypes));
    }

    getUniqueDevelopers(operationID: string): string[] {
        const operationTypes = this.operations
            .filter((operation) => operation.operation_id === operationID)
            .map((operation) => operation.author_email);

        // Return unique operation types
        return Array.from(new Set(operationTypes));
    }

    getOperationStatus(operationID: string): string {
        const statuses = this.operations
            .filter((operation) => operation.operation_id === operationID)
            .map((operation) => operation.status);

        if (statuses.length === 0) return "N/A";

        // If all statuses are the same, return that status
        const uniqueStatuses = Array.from(new Set(statuses));
        if (uniqueStatuses.length === 1) {
            return uniqueStatuses[0];
        }

        // If every status includes "fail", return "Failed"
        if (statuses.every((status) => status.toLowerCase().includes("fail"))) {
            return "Failed";
        }

        // If all statuses include "complete", return "Complete"
        if (statuses.every((status) => status.toLowerCase().includes("complete"))) {
            return "Complete";
        }

        // Otherwise, return "OnGoing"
        return "On-Going";
    }

    getStatus(): string {
        const statuses = this.operations.map((operation) => operation.status);

        if (statuses.length === 0) return "N/A";

        // If all statuses are the same, return that status
        const uniqueStatuses = Array.from(new Set(statuses));
        if (uniqueStatuses.length === 1) {
            return uniqueStatuses[0];
        }

        // If every status includes "fail", return "Failed"
        if (statuses.every((status) => status.toLowerCase().includes("fail"))) {
            return "Failed";
        }

        // If all statuses include "complete", return "Complete"
        if (statuses.every((status) => status.toLowerCase().includes("complete"))) {
            return "Complete";
        }

        // Otherwise, return "On-Going"
        return "On-Going";
    }

    operationIDCanExpand(operationID: string): boolean {
        return this.operations
            .filter((operation) => operation.operation_id === operationID)
            .some((operation) => operation.can_expand);
    }

    operationIDCanRevert(operationID: string): boolean {
        return this.operations
            .filter((operation) => operation.operation_id === operationID)
            .some((operation) => operation.can_revert);
    }

    operationIDHasActions(operationID: string): boolean {
        return this.operationIDCanExpand(operationID) || this.operationIDCanRevert(operationID)
    }

}

export class ListSCPOperationsResponse {
    operations: SCPOperation[];
    constructor(operations: SCPOperation[] = []) {
        this.operations = operations;
    }

    static fromJSON(data: any): ListSCPOperationsResponse {
        const operations = data.operations.map((op: any) => new SCPOperation(op));
        return new ListSCPOperationsResponse(operations);
    }
}

export class SCPOperation {
    commit_id: string;
    commit_title: string;
    author_email: string;
    operation_id: string;
    mpa_id: string;
    auto_mode: boolean;
    aws_path: string;
    current_stagger_stage_completed_timestamp: string;
    is_falcon: boolean;
    operation_type: string;
    scp_id: string;
    scp_name: string;
    stagger_level: string;
    stagger_stage: string;
    status: string;
    timestamp_of_operation_creation: string;
    can_expand: boolean;
    can_revert: boolean;

    constructor(data: {
        commit_id: string;
        commit_title: string;
        author_email: string;
        operation_id: string;
        mpa_id: string;
        auto_mode: boolean;
        aws_path: string;
        current_stagger_stage_completed_timestamp: string;
        is_falcon: string;
        operation_type: string;
        scp_id: string;
        scp_name: string;
        stagger_level: string;
        stagger_stage: string;
        status: string;
        timestamp_of_operation_creation: string;
        can_expand: boolean;
        can_revert: boolean;
    }) {
        this.commit_id = data.commit_id;
        this.commit_title = data.commit_title;
        this.author_email = data.author_email;
        this.operation_id = data.operation_id;
        this.mpa_id = data.mpa_id;
        this.auto_mode = data.auto_mode;
        this.aws_path = data.aws_path;
        this.current_stagger_stage_completed_timestamp = data.current_stagger_stage_completed_timestamp;
        this.is_falcon = data.is_falcon === "true";
        this.operation_type = data.operation_type;
        this.scp_id = data.scp_id;
        this.scp_name = data.scp_name;
        this.stagger_level = data.stagger_level;
        this.stagger_stage = data.stagger_stage;
        this.status = data.status;
        this.timestamp_of_operation_creation = data.timestamp_of_operation_creation;
        this.can_expand = data.can_expand ? data.can_expand : false;
        this.can_revert = data.can_revert ? data.can_revert : false;
    }

    hasActions(): boolean {
        return this.can_expand || this.can_revert;
    }

    static fromJSON(json: any): SCPOperation {
        return new SCPOperation(json);
    }
}