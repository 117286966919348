import { GUSApiService } from "./service";
import {ChangeCaseImplementationStep, ChangeCaseMetadata, GenerateMockImplSteps} from "../../utils/types/gus";
import { useUser } from "../../context/users";



// Mock implementation of GUSApiService
export class MockGUSApiService implements GUSApiService {
    // Method to fetch mock change case metadata
    async fetchChangeCaseMetadata(
        caseID: string,
        deploymentType: string,
        commitID: string
    ): Promise<ChangeCaseMetadata> {
        const ccMetadata = new ChangeCaseMetadata();

        // Populate mock data for ChangeCaseMetadata
        ccMetadata.metadata.subject = "Mock change case subject";
        ccMetadata.admin_actions_data.allowed = true;
        ccMetadata.metadata.implementation_steps = GenerateMockImplSteps(100); // Generate 100 mock steps
        ccMetadata.metadata.allowed_deployment_types = ["StackSetsTemplate", "SAM", "SCPPolicy"];

        // Return a Promise resolving with the mock data after a delay
        return new Promise<ChangeCaseMetadata>(resolve => {
            setTimeout(() => {
                resolve(ccMetadata);
            }, 500); // Simulate an async delay (500ms)
        });
    }
}
