import React from 'react';
import { Accordion, AccordionPanel, Card } from '@salesforce/design-system-react';
import {CONSTANT_GENERIC_DEPLOYMENTS} from "../../../../constants/themes";
import ThemedBadge from "../../../../utils/components/tag/tag";  // Ensure you import your custom component if applicable

// Types
interface StackSetsDeployment {
    // Define the properties based on your actual type
    commit_id: string;
}

interface StackSetOperation {
    staggers: Array<{
        stagger_group_name: string;
        stackset_status: string;
        operation_type: string;
        getCreatedAtDate: () => string;
        getUpdatedAtDate: () => string;
    }>;
}

interface StackSetStagger {
    stagger_group_name: string;
}

interface StaggerCardProps {
    selectedCommit: StackSetsDeployment | null;
    selectedStackSet: StackSetOperation | null;
    selectedStagger: StackSetStagger | null;
    handleSelectStagger: (stagger: any) => void;
    getDetailsLi: (label: string, value: string) => JSX.Element;
}

const StaggerCard: React.FC<StaggerCardProps> = ({
                                                     selectedCommit,
                                                     selectedStackSet,
                                                     selectedStagger,
                                                     handleSelectStagger,
                                                     getDetailsLi
                                                 }) => {
    // Ensure data is available before rendering
    if (!selectedCommit || !selectedStackSet || selectedStackSet.staggers.length === 0) {
        return null;
    }

    return (
        <Card className="" bodyClassName="" hasNoHeader={true}>
            <div className="slds-grid slds-wrap">
                <div className="slds-col slds-size_5-of-5">
                    <h2 className="slds-text-title_bold slds-text-color_weak slds-m-bottom_x-small">
                        Stagger Groups [{selectedStackSet.staggers.length}]
                    </h2>
                    <Accordion>
                        {selectedStackSet.staggers.map((stagger, index) => (
                            <AccordionPanel
                                key={index}
                                expanded={selectedStagger?.stagger_group_name === stagger.stagger_group_name}
                                onTogglePanel={() => handleSelectStagger(stagger)}
                                summary={
                                    <div className="slds-text-wrap" style={{ whiteSpace: 'normal' }}>
                                        <ThemedBadge
                                            className="slds-m-right_medium"
                                            theme={CONSTANT_GENERIC_DEPLOYMENTS.GET_STATUS_THEME(stagger.stackset_status, true)}
                                            text={stagger.stackset_status}
                                        />
                                        {`${stagger.stagger_group_name}`}
                                    </div>
                                }
                                className="slds-box slds-m-bottom_small"
                            >
                                <div className="slds-grid slds-box">
                                    <ul className="slds-list_dotted">
                                        {getDetailsLi("Operation Type", stagger.operation_type)}
                                        {getDetailsLi("Created At", stagger.getCreatedAtDate())}
                                        {getDetailsLi("Updated At", stagger.getUpdatedAtDate())}
                                    </ul>
                                </div>
                            </AccordionPanel>
                        ))}
                    </Accordion>
                </div>
            </div>
        </Card>
    );
}

export default StaggerCard;
