import {MockDriftDetectionApiService} from "./mock_api_repository";
import {MainDriftDetectionApiService} from "./api_repository"
import {DriftDetectionApiService} from "./service"
import {API_MOCK_STATUS} from "../environment/environment";

export class DriftDetectionApiServiceFactory {
    static getService(): DriftDetectionApiService {
        if (API_MOCK_STATUS.isDriftDetectionMockEnabled()) {
            return new MockDriftDetectionApiService();
        } else {
            return new MainDriftDetectionApiService();
        }
    }
}