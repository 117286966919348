export class GenericDeploymentSubmissionResponse {
    message: string;
    success: boolean;
    errors: string[];

    constructor(message: string, success: boolean, errors: string[] = []) {
        this.message = message;
        this.success = success;
        this.errors = errors;
    }

    static fromJSON(json: Record<string, any>): GenericDeploymentSubmissionResponse {
        // Default values
        const defaultMessage = "";
        const defaultSuccess = false;
        const defaultErrors: string[] = [];

        // Type-safe property extraction
        const message = typeof json.message === 'string' ? json.message : defaultMessage;
        const success = typeof json.success === 'boolean' ? json.success : defaultSuccess;
        const errors = Array.isArray(json.errors) && json.errors.every(err => typeof err === 'string')
            ? json.errors
            : defaultErrors;

        return new GenericDeploymentSubmissionResponse(message, success, errors);
    }
}
