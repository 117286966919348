import {MockUserApiService} from "./mock_api_repository";
import {MainUserApiService} from "./api_repository"
import {UserApiService} from "./service"
import {API_MOCK_STATUS} from "../environment/environment";
export class UserApiServiceFactory {
    static getService(): UserApiService {
        if (API_MOCK_STATUS.isUserMockEnabled()) {
            return new MockUserApiService();
        } else {
            return new MainUserApiService();
        }
    }
}
