import {MockClosureApiService} from "./mock_api_repository";
import {MainAccountClosureApiService} from "./api_repository"
import {AccountClosureApiService} from "./service"
import {API_MOCK_STATUS} from "../environment/environment";
export class AccountClosureApiServiceFactory {
    static getService(): AccountClosureApiService {
        if (API_MOCK_STATUS.isAccountClosureMockEnabled()) {
            return new MockClosureApiService();
        } else {
            return new MainAccountClosureApiService();
        }
    }
}
