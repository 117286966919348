import {MockStackSetsDeploymentApiService} from "./mock_api_repository";
import {MainStackSetsDeploymentApiService} from "./api_repository"
import {StackSetsDeploymentApiService} from "./service";
import {API_MOCK_STATUS} from "../../environment/environment";
export class StackSetsDeploymentApiServiceFactory {
    static getService(): StackSetsDeploymentApiService {
        if (API_MOCK_STATUS.isStackSetsMockEnabled()) {
            return new MockStackSetsDeploymentApiService();
        } else {
            return new MainStackSetsDeploymentApiService();
        }
    }
}
