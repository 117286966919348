export class ChangeCaseMetadata {
    admin_actions_data: {
        allowed: boolean;
        errors: string[];
    };
    metadata: {
        id: string;
        subject: string;
        status: string;
        owner_email: string;
        description: string;
        is_closed: boolean;
        category: string;
        allowed_deployment_types: string[];
        implementation_steps: ChangeCaseImplementationStep[];
        commit_ids: string[];
        scp_operation_ids: string[];
        shadow_implementors: string[];
    };

    // Constructor to initialize default values
    constructor() {
        this.admin_actions_data = { allowed: false, errors: [] };
        this.metadata = {
            id: "",
            subject: "",
            status: "",
            owner_email: "",
            description: "",
            is_closed: false,
            category: "",
            allowed_deployment_types: [],
            implementation_steps: [],
            commit_ids: [],
            scp_operation_ids: [],
            shadow_implementors: []
        };
    }

    /**
     * Creates a ChangeCaseMetadata instance from a JSON object.
     * @param json The JSON object to parse.
     * @returns An instance of ChangeCaseMetadata.
     */
    static fromJSON(json: any): ChangeCaseMetadata {
        const instance = new ChangeCaseMetadata();

        // Safely populate admin_actions_data
        instance.admin_actions_data = {
            allowed: json?.admin_actions_data?.allowed || false,
            errors: json?.admin_actions_data?.errors || []
        };

        // Safely populate metadata
        instance.metadata = {
            id: json?.metadata?.id || "",
            subject: json?.metadata?.subject || "",
            status: json?.metadata?.status || "",
            owner_email: json?.metadata?.owner_email || "",
            description: json?.metadata?.description || "",
            is_closed: json?.metadata?.is_closed || false,
            category: json?.metadata?.category || "",
            allowed_deployment_types: json?.metadata?.allowed_deployment_types || [],
            implementation_steps: json?.metadata?.implementation_steps?.map((step: any) =>
                ChangeCaseImplementationStep.fromJSON(step)
            ) || [],
            commit_ids: json?.metadata?.commit_ids || [],
            scp_operation_ids: json?.metadata?.scp_operation_ids || [],
            shadow_implementors: json?.metadata?.shadow_implementors || []
        };
        return instance;
    }

    /**
     * Gets the implementation step by its step_id.
     * @param step_id The ID of the implementation step.
     * @returns The matching ChangeCaseImplementationStep, or null if not found.
     */
    getImplementationStep(step_id: string): ChangeCaseImplementationStep | null {
        debugger
        const step = this.metadata.implementation_steps.find((implStep) => implStep.name == step_id);
        return step || null;
    }
}

export class ChangeCaseImplementationStep {
    id: string;
    name: string;
    description: string;
    status: string;
    plannedDurationHours: number;
    plannedStartTime: string;
    startTime: string;
    endTime: string;

    constructor(
        id: string,
        name: string,
        description: string,
        status: string,
        plannedDurationHours: number,
        plannedStartTime: string,
        startTime: string,
        endTime: string
    ) {
        this.id = id;
        this.name = name;
        this.description = description;
        this.status = status;
        this.plannedDurationHours = plannedDurationHours;
        this.plannedStartTime = plannedStartTime;
        this.startTime = startTime;
        this.endTime = endTime;
    }

    getLabel(): string {
        return `${this.name} - ${this.description}`
    }

    /**
     * Creates an ImplementationStep instance from a JSON object.
     * @param json The JSON object to parse.
     * @returns An instance of ImplementationStep.
     */
    static fromJSON(json: Record<string, any>): ChangeCaseImplementationStep {
        return new ChangeCaseImplementationStep(
            json.id || "",
            json.name || "",
            json.description || "",
            json.status || "",
            json.planned_duration_hours || 0,
            json.planned_start_time || "",
            json.start_time || "",
            json.end_time || ""
        );
    }
}

export const GenerateMockImplSteps = (count: number): ChangeCaseImplementationStep[] => {
    return Array.from({ length: count }, (_, index) => {
        const step = new ChangeCaseImplementationStep(
            `Mock id ${index + 1}`,
            `Mock name ${index + 1}`,
            `Mock description ${index + 1}`,
            "not started",
            4,
            `2024-09-24T10:29:00.000+0000`,
            "",
            ""
        );
        return step;
    });
};