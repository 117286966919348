export class FetchArtifactsResponse {
    /**
     * List of artifact names.
     */
    public artifactNames: string[];

    /**
     * Marker for pagination.
     */
    public marker: string;

    /**
     * Constructor to initialize the artifact names and marker.
     * @param artifactNames - List of artifact names.
     * @param marker - Marker for pagination.
     */
    constructor(artifactNames: string[] = [], marker: string = "") {
        this.artifactNames = artifactNames;
        this.marker = marker;
    }

    /**
     * Adds an artifact name to the list.
     * @param artifactName - The artifact name to add.
     */
    public addArtifactName(artifactName: string): void {
        if (artifactName && typeof artifactName === "string") {
            this.artifactNames.push(artifactName);
        } else {
            throw new Error("Invalid artifact name. It must be a non-empty string.");
        }
    }

    /**
     * Removes an artifact name from the list.
     * @param artifactName - The artifact name to remove.
     */
    public removeArtifactName(artifactName: string): void {
        const index = this.artifactNames.indexOf(artifactName);
        if (index > -1) {
            this.artifactNames.splice(index, 1);
        } else {
            throw new Error("Artifact name not found in the list.");
        }
    }

    /**
     * Checks if an artifact name exists in the list.
     * @param artifactName - The artifact name to check.
     * @returns True if the artifact name exists, otherwise false.
     */
    public hasArtifactName(artifactName: string): boolean {
        return this.artifactNames.includes(artifactName);
    }

    /**
     * Gets all artifact names.
     * @returns List of artifact names.
     */
    public getArtifactNames(): string[] {
        return [...this.artifactNames];
    }

    /**
     * Gets the marker value.
     * @returns The marker.
     */
    public getMarker(): string {
        return this.marker;
    }

    /**
     * Sets the marker value.
     * @param marker - The marker to set.
     */
    public setMarker(marker: string): void {
        if (typeof marker === "string") {
            this.marker = marker;
        } else {
            throw new Error("Invalid marker. It must be a string.");
        }
    }

    /**
     * Static function to create an instance from JSON.
     * @param json - JSON object containing artifact names and marker.
     * @returns Instance of FetchArtifactsResponse.
     */
    public static fromJSON(json: unknown): FetchArtifactsResponse {
        if (
            typeof json !== "object" ||
            json === null ||
            !Array.isArray((json as any).artifact_names) ||
            !(json as any).artifact_names.every((name: unknown) => typeof name === "string") ||
            ("marker" in json && typeof (json as any).marker !== "string")
        ) {
            return new FetchArtifactsResponse();
        }

        return new FetchArtifactsResponse((json as any).artifact_names, (json as any).marker || "");
    }
}
