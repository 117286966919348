import React from "react";

interface ThemedBadgeProps {
    theme: string;
    text?: string;
    className?: string;
}

const ThemedBadge: React.FC<ThemedBadgeProps> = ({ theme, text, className }) => {

    return (
        <span className={`slds-badge ${theme} slds-text-color_white ${className}`}>
            {text || theme.charAt(0).toUpperCase() + theme.slice(1)}
        </span>
    );
};

export default ThemedBadge;
