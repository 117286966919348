import {ListSCPOperationsResponse, SCPDeployment} from "../../../utils/types/deployments/scp/operations";
import {SCPDeploymentApiService} from "./service";
import {BASE_URL, HandleErrorResponse} from "../../common";
import {GenericDeploymentSubmissionResponse} from "../../../utils/types/deployments/genericDeploymentResponse";
import {GenericServiceSummary} from "../../../utils/types/genericServiceSummaryItem";

export class MainSCPDeploymentApiService implements SCPDeploymentApiService {
    readonly adminBasePath = `${BASE_URL}/pcsk-admin/deployments/scp`;

    async getOperations(): Promise<SCPDeployment[]> {
        const endpoint = `${this.adminBasePath}`;

        try {
            const response = await fetch(endpoint, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                    'Content-Type': 'application/json',
                }
            });

            if (!response.ok) {
                await HandleErrorResponse(response);
            }

            const resp = await response.json();
            return SCPDeployment.GroupByCommit(ListSCPOperationsResponse.fromJSON(resp).operations);
        } catch (error) {
            console.error("Error fetching artifacts:", error);
            throw error;
        }
    }

    async expandOperation(changeCase: string, operationID: string): Promise<GenericDeploymentSubmissionResponse> {
        const endpoint = `${this.adminBasePath}/expand`;

        try {
            const response = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    operation_id: operationID,
                    change_case: changeCase,
                })
            });

            if (!response.ok) {
                await HandleErrorResponse(response);
            }

            const resp = await response.json();
            return GenericDeploymentSubmissionResponse.fromJSON(resp);
        } catch (error) {
            console.error("Error fetching artifacts:", error);
            throw error;
        }
    }

    async rollbackOperation(changeCase: string, operationID: string): Promise<GenericDeploymentSubmissionResponse> {
        const endpoint = `${this.adminBasePath}/rollback`;
        try {
            const response = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    operation_id: operationID,
                    change_case: changeCase,
                })
            });

            if (!response.ok) {
                await HandleErrorResponse(response);
            }

            const resp = await response.json();
            return GenericDeploymentSubmissionResponse.fromJSON(resp);
        } catch (error) {
            console.error("Error fetching artifacts:", error);
            throw error;
        }
    }

    async getSummary(): Promise<GenericServiceSummary> {
        try {
            const finalURI = `${BASE_URL}/pcsk-admin/summary/operations/scp`;
            const response = await fetch(finalURI, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('access_token')
                },
            });

            if (!response.ok) {
                await HandleErrorResponse(response);
            }

            const jsonResp: any[] = await response.json();
            return GenericServiceSummary.fromJSON(jsonResp);

        } catch (error) {
            console.error("Error fetching stack set drift summary :", error);
            throw error;

        }
    }
}
