import React, {useState, useEffect, useRef} from 'react';
import { Combobox, comboboxFilterAndLimit } from '@salesforce/design-system-react';
import { ChangeCaseImplementationStep, ChangeCaseMetadata } from "../../types/gus";

interface ImplementationStepSelectorProps {
    changeCaseMetadata: ChangeCaseMetadata | null;
    setSelectedStep: React.Dispatch<React.SetStateAction<string>>;
    disable: boolean;
}

type FilteredStep = { id: string; label: string; step: ChangeCaseImplementationStep };

// Helper function to generate selection structure
const getSelection = (
    step: ChangeCaseImplementationStep | null
): FilteredStep[] => {
    if (step) {
        return [{ id: step.id, label: step.getMinimalDetails(), step }];
    }
    return [];
};

const ImplementationStepSelector: React.FC<ImplementationStepSelectorProps> = ({
                                                                                   changeCaseMetadata,
                                                                                   setSelectedStep,
                                                                                   disable,
                                                                               }) => {
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [autoPopulate, setAutoPopulate] = useState<boolean>(true);
    const [filteredSteps, setFilteredSteps] = useState<FilteredStep[]>([]);
    const [selectedImplementationStep, setSelectedImplementationStep] = useState<ChangeCaseImplementationStep | null>(null);


    // Effect to filter implementation steps whenever searchTerm or metadata changes
    useEffect(() => {
        if (!changeCaseMetadata) return;
        const steps = changeCaseMetadata.metadata.implementation_steps.map((step) => ({
            id: step.id,
            label: step.getLabel(),
            step: step,
        })) || [];
        const filtered = steps.filter((step) =>
            step.label.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredSteps(filtered);
    }, [searchTerm, changeCaseMetadata]);

    // Effect to reset when disabled
    useEffect(() => {
        !changeCaseMetadata ? resetFC() : setAutoPopulate(true)
    }, [changeCaseMetadata]);

    // Effect to call parents method
    useEffect(() => {
        if (!selectedImplementationStep) {
            setSelectedStep('');
            return
        }
        setSelectedStep(selectedImplementationStep.id);
    }, [selectedImplementationStep]);

    // Effect to auto-populate the first valid implementation step
    useEffect(() => {
        if (filteredSteps.length > 0 && !selectedImplementationStep && autoPopulate) {
            // Loop through the filtered steps and find the first valid implementation step
            for (const step of filteredSteps) {
                const dateObj = step.step.getLocalPlannedStartDate();

                if (dateObj.isValid && step.step.status.toLowerCase() === "pending implementation") {
                    // Check if the step's planned start time is either before now or within the next 30 minutes
                    if (isBeforeNow(dateObj.dateObj) || isWithinNextMinutes(dateObj.dateObj, 30)) {
                        // Select the first valid implementation step and break the loop
                        selectImplementationStep(step);
                        break;
                    }
                }
            }
            setAutoPopulate(false)
        }
    }, [filteredSteps, selectedImplementationStep]);

    const selectImplementationStep = (step: FilteredStep | null | undefined) => {
        if (!step) {
            setSelectedImplementationStep(null);
            setSearchTerm('');
            return
        }
        setSelectedImplementationStep(step.step);
        setSearchTerm(step.label);
    }

    const resetFC = (): void => {
        setSelectedStep('');
        setSearchTerm('');
        setSelectedImplementationStep(null);
    };

    const isBeforeNow = (date: Date): boolean => {
        return date.getTime() < new Date().getTime();
    };

    const isWithinNextMinutes = (date: Date, minutes: number): boolean => {
        const now = new Date();
        const targetTime = new Date(now.getTime() + minutes * 60000);
        return date.getTime() <= targetTime.getTime();
    };

    const getBottomContent = (): JSX.Element | undefined => {
        if (!selectedImplementationStep) return;

        let errors: string[] = [];

        if (selectedImplementationStep.status.toLowerCase() !== "pending implementation") {
            errors.push(`Step has status '${selectedImplementationStep.status}'. Allowed status is 'Pending Implementation'`);
        }

        const dateObj = selectedImplementationStep.getLocalPlannedStartDate();
        if (dateObj.isValid) {
            if (isBeforeNow(dateObj.dateObj) || isWithinNextMinutes(dateObj.dateObj, 30)) {
                return (
                    <ul className="slds-list_dotted">
                        <li key={0} className={"slds-text-color_success"}>
                            {`Planned Start Time: ${dateObj.dateObj.toLocaleString()} [Duration: ${selectedImplementationStep.plannedDurationHours} ${selectedImplementationStep.plannedDurationHours === 1 ? 'hour' : 'hours'}]`}
                        </li>
                    </ul>
                );
            }

            // Invalidate selection
            setSelectedStep('');
            errors.push(`Planned Start Time begins more than 30 minutes later '${dateObj.dateObj.toLocaleString()}'`);
            return (
                <ul className="slds-list_dotted">
                    {errors.map((msg, index) => (
                        <li key={index} className={"slds-text-color_error"}>
                            {msg}
                        </li>
                    ))}
                </ul>
            );
        }

        // unknown date formats
        return (
            <ul className="slds-list_dotted">
                <li key={0} className={"slds-text-color_weak"}>
                    {selectedImplementationStep.plannedStartTime}
                </li>
            </ul>
        );
    };

    return (
        <div className="slds-size_1-of-1">
            {disable ? (
                <input
                    type="text"
                    className={`slds-input ${disable ? 'slds-is-disabled' : ''}`}
                    placeholder="Search by implementation Step ID or Name"
                    value={searchTerm}
                    disabled={disable}
                />
            ) : (
                <Combobox
                    id="implementation_step_picker_combobox"
                    events={{
                        onSelect: (event: React.ChangeEvent<HTMLInputElement>, data: { selection: FilteredStep[] }) => {
                            if (data.selection && data.selection.length > 0) {
                                selectImplementationStep(data.selection[0])
                            }
                        },
                        onChange: (event: React.ChangeEvent<HTMLInputElement>, data: { value: string }) => {
                            setSearchTerm(data.value);
                        },
                        onRequestRemoveSelectedOption: resetFC,
                    }}
                    labels={{
                        placeholder: 'Search by implementation Step ID or Name',
                        noOptionsFound: 'No matches found.',
                    }}
                    menuPosition="absolute"
                    options={comboboxFilterAndLimit({
                        inputValue: searchTerm,
                        options: filteredSteps,
                        selection: getSelection(selectedImplementationStep),
                        limit: 100,
                    })}
                    value={searchTerm}
                    selection={getSelection(selectedImplementationStep)}
                    variant="inline-listbox"
                    menuItemVisibleLength={5}
                />
            )}
            {getBottomContent()}
        </div>
    );
};

export default ImplementationStepSelector;
