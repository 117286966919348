import {BASE_URL, HandleErrorResponse} from "../common";
import {GUSApiService} from "./service"
import {ChangeCaseMetadata} from "../../utils/types/gus";

export class MainGUSApiService implements GUSApiService {
    readonly basePath = `${BASE_URL}/pcsk-admin`;
    async fetchChangeCaseMetadata(caseID: string, deploymentType: string, commitID: string): Promise<ChangeCaseMetadata> {

        let endpoint = `${this.basePath}/change-case/metadata/${caseID}?commit_id=${encodeURIComponent(commitID)}&deployment_type=${encodeURIComponent(deploymentType)}&owner=${encodeURIComponent(true)}`;

        try {
            const response = await fetch(endpoint, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('access_token')
                }
            });

            if (!response.ok) {
                await HandleErrorResponse(response);
            }
            return ChangeCaseMetadata.fromJSON(await response.json());
        } catch (error) {
            console.error("Error fetching change case data:", error);
            throw error;
        }
    }
}

