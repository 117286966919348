import React, { useState } from 'react';
import { AddToastFunction } from "../../../utils/types/toast";
import {Card, Icon, Accordion, AccordionPanel, Avatar, Button} from "@salesforce/design-system-react";
import StackSetDriftSummaryCard from "./StacksetDriftSummary";
import StackSetDeploymentSummaryCard from "./StacksetDeploymentSummary";
import QuickLinkList, {QuickLinkItem} from "../../../utils/components/quick_links/QuickLinks";
import SCPOperationsSummaryCard from "./SCPOperationsSummary";
import {ADMIN_SERVICE_STATUS} from "../../../services/environment/environment";

// FAQ data
const FAQs = [
    {
        question: "How to deploy a new commit in stackset template ?",
        answers: [
            "visit <a href='https://salesforce.quip.com/ntQgAbKVstB9' target='_blank'>stacksets template deployment guide</a>"
        ]
    },
    {
        question: "How to deploy a new commit in stackset infrastructure ?",
        answers: [
            "stacksets infrasture is deployed using SAM",
            "visit <a href='https://salesforce.quip.com/MhU2AztXE3xL' target='_blank'>SAM deployment guide</a>"
        ]
    },
    {
        question: "How to make a SAM deployment",
        answers: [
            "visit <a href='https://salesforce.quip.com/MhU2AztXE3xL' target='_blank'>SAM deployment guide</a>"
        ]
    },
    {
        question: "How expand/revert SCP operation ?",
        answers: [
            "visit <a href='https://salesforce.quip.com/cve4AxjvoSoU' target='_blank'>SCP deployment guide</a>"
        ]
    },
    {
        question: "How to analyse stacksets drift results ?",
        answers: [
            "visit <a href='https://salesforce.quip.com/uyg3AJgFatk5' target='_blank'>stacksets drift detection guide</a>"
        ]
    }
];

const FAQCard: React.FC = () => {
    const [activePanels, setActivePanels] = useState<string[]>([]);

    const handleTogglePanel = (panelId: string) => {
        setActivePanels((prevPanels) => prevPanels.includes(panelId)
            ? prevPanels.filter(id => id !== panelId)
            : [...prevPanels, panelId]);
    };

    return (
        <Card
            className="slds-card_boundary"
            heading={<p className="slds-text-heading_medium">FAQs & Guidelines</p>}
            icon={<Icon category="standard" name="question_feed" size="small"/>}
        >
            <Accordion>
                {FAQs.map((faq, index) => (
                    <AccordionPanel
                        key={index}
                        expanded={activePanels.includes(`panel${index}`)}
                        onTogglePanel={() => handleTogglePanel(`panel${index}`)}
                        summary={index + 1 + ". " + faq.question}
                        className="slds-box slds-m-bottom_small"
                    >
                        <ul className="slds-list_dotted">
                            {faq.answers.map((answer, answerIndex) => (
                                <li key={answerIndex} dangerouslySetInnerHTML={{ __html: answer }} />
                            ))}
                        </ul>
                    </AccordionPanel>
                ))}
            </Accordion>
        </Card>
    );
};

const quickLinks: QuickLinkItem[] = [
    { text: "PCSK Admin Design", link: "https://salesforce.quip.com/31d4A7oh9N8L" },
    { text: "AWS Master Repo", link: "https://git.soma.salesforce.com/identity-access/aws-master/tree/master" },
    { text: "StackSets Repo", link: "https://git.soma.salesforce.com/identity-access/pcsk-stacksets" },
    { text: "New Deployment Process Runbook", link: "https://salesforce.quip.com/3jXhALmbFKDA" },
    { text: "PCSK Troubleshooting Guide", link: "https://salesforce.quip.com/1c4HA0Gl48Vr" },
    { text: "PCSK Availability", link: "https://tabse.internal.salesforce.com/views/UnifiedServiceHealthREADS/SFHealthTrend?Senior%20Engineering%20Leader=Paul%20Constantinides&Service%2FFeature=pcsk-jit&%3Aembed=y&%3Aoriginal_view=y#1" },
];


type PCSKAdminHomePageProps = {
    addToast: AddToastFunction;
};

const PCSKAdminHomePage: React.FC<PCSKAdminHomePageProps> = ({ addToast }) => {

    return (
        <>
            <div className={"slds-m-left_small"}>
                <QuickLinkList usefulPCSKLinks={quickLinks} />
            </div>
        <div
            className="slds-grid slds-wrap slds-m-left_medium slds-m-right_medium slds-m-bottom_medium">

            {/* StackSets Drifts Summary */}
            <StackSetDriftSummaryCard/>

            {/* StackSets Deployment Summary */}
            <StackSetDeploymentSummaryCard/>

            {/* SCP Deployments Summary */}
            {ADMIN_SERVICE_STATUS.isSCPDeploymentEnabled() && <SCPOperationsSummaryCard/>}

            {/* separator */}
            <div className="slds-col slds-size_1-of-1 slds-large-size_1-of-1"></div>

            {/* FAQs */}
            <div className="slds-col slds-size_1-of-1 slds-large-size_2-of-3 slds-p-around--x-small">
                <FAQCard/>
            </div>
        </div>
        </>
    );
};

export default PCSKAdminHomePage;
