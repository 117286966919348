import React from "react";
import { Button, Icon, Popover } from "@salesforce/design-system-react";

interface ToggleWithPopoverProps {
    labelSuffix: string; // Suffix for the toggle switch label
    isChecked: boolean; // Checked state of the toggle
    isDisabled?: boolean; // Optional: Disable the toggle
    onToggle: (checked: boolean) => void; // Callback for toggle change
    popoverHeading?: string; // Heading for the popover
    popoverBody?: string; // Content for the popover
}

const ToggleWithPopover: React.FC<ToggleWithPopoverProps> = ({
                                                                 labelSuffix,
                                                                 isChecked,
                                                                 isDisabled = false,
                                                                 onToggle,
                                                                 popoverHeading = "Help",
                                                                 popoverBody = "Additional information about the toggle.",
                                                             }) => {
    return (
        <div className="slds-grid slds-gutters slds-m-horizontal_large slds-align-center">
            {/* Toggle Switch */}
            <div className="slds-form-element">
                <label className="slds-checkbox_toggle">
                    <input
                        type="checkbox"
                        checked={isChecked}
                        disabled={isDisabled}
                        onChange={() => onToggle(!isChecked)}
                    />
                    <span className="slds-checkbox_faux_container" aria-live="assertive">
                        <span className="slds-checkbox_faux"></span>
                        <span className="slds-checkbox_on">{`${labelSuffix}`}</span>
                        <span className="slds-checkbox_off">{`${labelSuffix}`}</span>
                      </span>
                </label>
            </div>

            <Popover
                className="slds-grid slds-wrap slds-grid_pull-padded"
                body={
                    <div className="slds-m-top--small">
                        <p>{popoverBody}</p>
                    </div>
                }
                heading={popoverHeading}
                id="toggle-popover"
                variant="feature"
            >
                <Button
                    assistiveText={{ icon: "Help" }}
                    iconCategory="utility"
                    iconName="question"
                    iconSize="small"
                    variant="icon"
                    className="slds-m-left_x-small"
                />
            </Popover>
        </div>
    );
};

export default ToggleWithPopover;
