import React, {useEffect, useState} from 'react';
import {Modal, Button, Card} from '@salesforce/design-system-react';
import {StackSetsDeployment} from "../../../../utils/types/deployments/stacksets/deployment";
import {StackSetsDeploymentApiServiceFactory} from "../../../../services/deployment/stacksets/factory";
import CustomSpinnerOverlay from "../../../../utils/components/spinner/custom_spinner_overlay";
import ChangeCaseInput from "../../../../utils/components/input/changeCaseInput";
import {GenericDeploymentSubmissionResponse} from "../../../../utils/types/deployments/genericDeploymentResponse";
import ToggleWithPopover from "../../../../components/pcsk_admin/deployment/utils/toggles";
import ComplianceChecks from "../../../../components/pcsk_admin/deployment/utils/compliance";
import ImplementationStepSelector from "../../../../utils/components/input/changeCaseImplementationSteps";
import {ChangeCaseMetadata} from "../../../../utils/types/gus";
import ThemedBadge from "../../../../utils/components/tag/tag";


interface ExpandCommitModalProps {
    isOpen: boolean;
    onClose: () => void;
    selectedCommit: StackSetsDeployment;
    onExpandSuccess: (apiResponse: GenericDeploymentSubmissionResponse) => void;
}

const ExpandStackSetCommitModal: React.FC<ExpandCommitModalProps> = ({ isOpen, onClose, onExpandSuccess, selectedCommit }) => {
    const [changeCaseID, setChangeCaseID] = useState<string>('');
    const [isValidChangeCase, setIsValidChangeCase] = useState<boolean>(false);
    const [isExpandInProgress, setIsExpandInProgress] = useState<boolean>(false);
    const [dryRun, setDryRun] = useState<boolean>(false);
    const [disableExpandButton, setDisableExpandButton] = useState<boolean>(false);
    const [forceExpand, setForceExpand] = useState<boolean>(false);
    const [acknowledged, setAcknowledged] = useState<boolean>(false);
    const [autoExpand, setAutoExpand] = useState<boolean>(false);
    const [expandAPIResponse, setExpandAPIResponse] = useState<GenericDeploymentSubmissionResponse | undefined>(undefined);
    const [ccMetadata, setCCMetadata] = useState<ChangeCaseMetadata | null>(null);
    const [selectedImplStepID, setSelectedImplStepID] = useState<string>('');
    const [triggerComplianceCheck, setTriggerComplianceCheck] = useState<boolean>(false);
    const [lowerEnvDeploymentComplete, setLowerEnvDeploymentComplete] = useState<boolean>(true);

    const stackSetsApiService = StackSetsDeploymentApiServiceFactory.getService();

    useEffect(() => {
        setDisableExpandButton(isExpandInProgress || !isValidChangeCase || !acknowledged || !isValidImplementationStep() || !lowerEnvDeploymentComplete)
    }, [isValidChangeCase, isExpandInProgress, lowerEnvDeploymentComplete, selectedImplStepID, acknowledged]);
    useEffect(() => {
        setTriggerComplianceCheck( isValidChangeCase && isValidImplementationStep())
    }, [isValidChangeCase, selectedImplStepID]);

    const handleExpand = async () => {
        try {
            setIsExpandInProgress(true);
            const expandResponse = await stackSetsApiService.expandCommit(changeCaseID, selectedImplStepID, selectedCommit.commit_id, selectedCommit.commit_title, forceExpand, autoExpand, dryRun);
            expandResponse.success && onExpandSuccess(expandResponse)
            setExpandAPIResponse(expandResponse)
        } catch (error) {
            console.log("Error submitting commit for Expansion", error);
            setExpandAPIResponse(new GenericDeploymentSubmissionResponse(`${error}`, false))
        } finally {
            setIsExpandInProgress(false);
        }
    };

    const disableImplStep = () => {
        return isExpandInProgress || !isValidChangeCase
    }
    const resetImplStep = () => {
        return !isValidChangeCase
    }
    const isValidImplementationStep = () => {
        return selectedImplStepID.length > 0
    }
    const disableComplianceChecks = () => {
        return isExpandInProgress || !isValidChangeCase || !isValidImplementationStep()
    }

    const resetComplianceChecks = () => {
        return !isValidChangeCase ||  !isValidImplementationStep()
    }

    const getBottomContent = () => {
        if (isExpandInProgress) {
            return <>
                <Card hasNoHeader={true}>
                    <div style={{minHeight: "10vh"}}>
                        <div className="slds-m-top_xx-large slds-flex slds-flex_column slds-align-items_center">
                            <CustomSpinnerOverlay size={"medium"}/>
                            <p className="slds-m-top_large slds-align_absolute-center slds-text-color--inverse-weak">
                                Submitting commit for Expansion
                            </p>
                        </div>
                    </div>
                </Card>
            </>
        }

        if (expandAPIResponse) {
            return <>
                <Card hasNoHeader={true}>
                    {expandAPIResponse.success ?
                        <div className="slds-m-around_medium slds-p-around_medium slds-box">
                            <h2 className="slds-text-heading_medium slds-text-align_center slds-m-bottom_medium slds-text-color_success">
                                Deployment Request Submitted Successfully!
                            </h2>
                        </div> :
                        <div className="slds-p-around_medium slds-box">
                            <div className="slds-text-wrap slds-size_1-of-1 slds-m-vertical--medium">
                                <ThemedBadge
                                    className="slds-m-right_medium"
                                    theme={"slds-theme--error"}
                                    text={"Deployment Request Could Not Be Processed!"}
                                />
                            </div>
                            {expandAPIResponse.errors.length > 0 &&
                                <ul className="slds-list_dotted">
                                    {expandAPIResponse.errors.map((msg, index) => (
                                        <li key={index} className={"slds-text-color_destructive"}>
                                            {msg}
                                        </li>
                                    ))}
                                </ul>
                            }
                        </div>
                    }
                </Card>
            </>
        }

        return <>
            <Card hasNoHeader={true}>
                <div className="slds-p-around_x-large"></div>
            </Card>
        </>
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            disableClose={isExpandInProgress}
            size="medium"
            heading={
                <div>
                    <div>
                        <strong>Expand Commit:</strong> {selectedCommit.commit_id}
                    </div>
                    <div>
                        <em>{selectedCommit.commit_title}</em>
                    </div>
                </div>
            }

            dismissOnClickOutside={false}
            footer={
                <>
                    <div
                        className="slds-p-around_medium slds-grid slds-wrap slds-grid_align-spread slds-grid_vertical-align-center">
                        {/* Text and Checkbox - 10 parts */}
                        <div className="slds-col slds-size_10-of-12 slds-grid">
                            <label className="slds-checkbox_toggle slds-grid">
                                <input
                                    type="checkbox"
                                    checked={acknowledged}
                                    disabled={isExpandInProgress || !isValidChangeCase}
                                    onChange={() => {
                                        setAcknowledged(!acknowledged)
                                    }}
                                />
                                <span className="slds-checkbox_faux_container" aria-live="assertive">
                                    <span className="slds-checkbox_faux"></span>
                                    <span className="slds-checkbox_on">Acknowledged</span>
                                    <span className="slds-checkbox_off">Acknowledge? </span>
                                </span>
                                <p className={"slds-m-left--large"}>I acknowledge that the deployment of the previous stagger group has been verified,
                                    and evidences are posted on change case. </p>
                            </label>
                        </div>
                        {/* Button - 2 parts */}
                        <div className="slds-col slds-size_2-of-12">
                            <Button
                                label="Expand"
                                onClick={handleExpand}
                                variant="brand"
                                disabled={disableExpandButton}
                            />
                        </div>
                    </div>
                </>

            }
        >
            <div className="slds-p-horizontal--medium slds-p-top--medium">
                <div className={"slds-box"}>
                    <div className="slds-grid slds-gutters slds-m-around_small">
                        <div className="slds-col slds-size_1-of-5">
                            <label className="slds-form-element__label"><strong>Change Case:</strong></label>
                        </div>
                        <div className="slds-col">
                            <ChangeCaseInput
                                isValidChangeCase={isValidChangeCase}
                                disableInput={isExpandInProgress}
                                changeCaseID={changeCaseID}
                                setChangeCaseID={setChangeCaseID}
                                setIsValidChangeCase={setIsValidChangeCase}
                                shouldContainCommitID={selectedCommit.commit_id}
                                setCCMetadata={setCCMetadata}
                                deploymentType={"StackSetsTemplate"}
                            ></ChangeCaseInput>
                        </div>
                    </div>
                    <div className="slds-grid slds-gutters slds-m-around_small">
                        <div className="slds-col slds-size_1-of-5">
                            <label className="slds-form-element__label"><strong>Implementation Step:</strong></label>
                        </div>
                        <div className="slds-size_2-of-3 slds-m-bottom--xx-large">
                            <div className="slds-grid slds-grid_vertical-align-center">
                                <div className="slds-col">
                                    <ImplementationStepSelector
                                        changeCaseMetadata={isValidChangeCase ? ccMetadata :  null}
                                        setSelectedStep={setSelectedImplStepID}
                                        disable={disableImplStep()}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="slds-p-right--medium">
                        <ToggleWithPopover
                            labelSuffix="Force Expand"
                            isChecked={forceExpand}
                            isDisabled={isExpandInProgress || !isValidChangeCase || !isValidImplementationStep()}
                            onToggle={(checked) => setForceExpand(checked)}
                            popoverHeading="Force Expand"
                            popoverBody="Overrides any ongoing stackset deployment."
                        />
                    </div>
                </div>
                {/*<div className={"slds-m-vertical--large"}>*/}
                {/*    <ComplianceChecks*/}
                {/*        triggerComplianceCheck={triggerComplianceCheck}*/}
                {/*        disable={disableComplianceChecks()}*/}
                {/*        reset={resetComplianceChecks()}*/}
                {/*        commitID={selectedCommit.commit_id}*/}
                {/*        deploymentType={"StackSetsTemplate"}*/}
                {/*        artifactName={""}*/}
                {/*        setLowerEnvDeploymentComplete={setLowerEnvDeploymentComplete}*/}
                {/*    />*/}
                {/*    {getBottomContent()}*/}
                {/*</div>*/}
                {getBottomContent()}
            </div>
        </Modal>
);
};

export default ExpandStackSetCommitModal;
