import {ArtifactApiService} from "./service";
import {MainArtifactApiService} from "./api_repository";
import {MockArtifactApiService} from "./mock_api_repository";
import {API_MOCK_STATUS} from "../environment/environment";
export class ArtifactsApiServiceFactory {
    static getService(): ArtifactApiService {
        if (API_MOCK_STATUS.isArtifactsMockEnabled()) {
            return new MockArtifactApiService();
        } else {
            return new MainArtifactApiService();
        }
    }
}
