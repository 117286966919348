import React, {useEffect, useState} from 'react';
import {Modal, Button, Card} from '@salesforce/design-system-react';
import ChangeCaseInput from "../../../../utils/components/input/changeCaseInput";
import CustomSpinnerOverlay from "../../../../utils/components/spinner/custom_spinner_overlay";
import {SCPDeploymentAPIServiceFactory} from "../../../../services/deployment/scp/factory";
import {GenericDeploymentSubmissionResponse} from "../../../../utils/types/deployments/genericDeploymentResponse";
import {ChangeCaseMetadata} from "../../../../utils/types/gus";
import ImplementationStepSelector from "../../../../utils/components/input/changeCaseImplementationSteps";

interface RollbackOperationModalProps {
    isOpen: boolean;
    onClose: () => void;
    selectedOperationID: string;
    selectedCommitID: string;
    selectedCommitTitle: string;
    onExpandSuccess: (apiResponse: GenericDeploymentSubmissionResponse) => void;
}

const SCPRollbackOperationModal: React.FC<RollbackOperationModalProps> = ({ isOpen, onClose, onExpandSuccess, selectedOperationID, selectedCommitID, selectedCommitTitle }) => {
    const [changeCaseID, setChangeCaseID] = useState<string>('');
    const [isValidChangeCase, setIsValidChangeCase] = useState<boolean>(false);
    const [isRollbackInProgress, setIsRollbackInProgress] = useState<boolean>(false);
    const [disableRollbackButton, setDisableRollbackButton] = useState<boolean>(false);
    const [rollbackAPIResponse, setRollbackAPIResponse] = useState<GenericDeploymentSubmissionResponse | undefined>(undefined);
    const [selectedImplStepID, setSelectedImplStepID] = useState<string>('');
    const [ccMetadata, setCCMetadata] = useState<ChangeCaseMetadata | null>(null);

    const scpAPIService = SCPDeploymentAPIServiceFactory.getService();

    useEffect(() => {
        setDisableRollbackButton(isRollbackInProgress || !isValidChangeCase || !isValidImplementationStep())
    }, [isValidChangeCase, isRollbackInProgress, selectedImplStepID]);

    const disableImplStep = () => {
        return isRollbackInProgress || !isValidChangeCase
    }
    const resetImplStep = () => {
        return !isValidChangeCase
    }
    const isValidImplementationStep = () => {
        return selectedImplStepID.length > 0
    }


    const handleRollback = async () => {
        try {
            setIsRollbackInProgress(true);
            const expandResponse = await scpAPIService.rollbackOperation(changeCaseID, selectedOperationID);
            expandResponse.success && onExpandSuccess(expandResponse)
            setRollbackAPIResponse(expandResponse)
        } catch (error) {
            console.log("Error submitting scp operation for Rollback", error);
            setRollbackAPIResponse(new GenericDeploymentSubmissionResponse(`${error}`, false))
        } finally {
            setIsRollbackInProgress(false);
        }
    };

    const getBottomContent = () => {
        if (isRollbackInProgress) {
            return <>
                <Card hasNoHeader={true}>
                    <div style={{minHeight: "25vh"}}>
                        <div className="slds-m-top_xx-large slds-flex slds-flex_column slds-align-items_center">
                            <CustomSpinnerOverlay size={"medium"}/>
                            <p className="slds-m-top_large slds-align_absolute-center slds-text-color--inverse-weak">
                                Submitting operation for Rollback
                            </p>
                        </div>
                    </div>
                </Card>
            </>
        }

        if (rollbackAPIResponse) {
            return <>
                <Card hasNoHeader={true}>
                    {rollbackAPIResponse.success ?
                        <div className="slds-m-around_medium slds-p-around_medium slds-box" style={{minHeight: "25vh"}}>
                            <h2 className="slds-text-heading_medium slds-text-align_center slds-m-bottom_medium slds-text-color_success">
                                Rollback Request Submitted Successfully!
                            </h2>
                        </div> :
                        <div className="slds-m-around_medium slds-p-around_medium slds-box" style={{minHeight: "25vh"}}>
                            <h2 className="slds-text-heading_medium slds-text-align_center slds-m-bottom_medium slds-text-color_destructive">
                                {rollbackAPIResponse.message}
                            </h2>
                        </div>
                    }
                </Card>
            </>
        }

        return <>
            <Card hasNoHeader={true}>
                <div className="slds-p-around_xx-large slds-m-around--xx-large"></div>
            </Card>
        </>
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            size="medium"
            heading={
                <div>
                    <div>
                        <strong className={"slds-text-color_destructive"}>Rollback
                            Operation:</strong> {selectedOperationID}
                    </div>
                    <div>
                        <em>{selectedCommitTitle}</em>
                    </div>
                </div>
            }

            dismissOnClickOutside={false}
            footer={
                <>
                    <Button label="Rollback" onClick={handleRollback} variant="brand" disabled={disableRollbackButton}/>
                </>
            }
        >
            <div className="slds-p-around_medium">
                <div className={"slds-box"}>
                    <div className="slds-grid slds-gutters slds-m-around_small">
                        <div className="slds-col slds-size_1-of-5">
                            <label className="slds-form-element__label"><strong>Change Case:</strong></label>
                        </div>
                        <div className="slds-col">
                            <ChangeCaseInput
                                isValidChangeCase={isValidChangeCase}
                                disableInput={isRollbackInProgress}
                                changeCaseID={changeCaseID}
                                setChangeCaseID={setChangeCaseID}
                                setIsValidChangeCase={setIsValidChangeCase}
                                deploymentType={"SCPPolicy"}
                                setCCMetadata={setCCMetadata}
                            ></ChangeCaseInput>
                        </div>
                    </div>

                    {/* custom min height maintains the visibility of dropdown options*/}
                    <div className="slds-grid slds-gutters slds-m-horizontal--small slds-m-top--small">
                        <div className="slds-col slds-size_1-of-5">
                            <label className="slds-form-element__label"><strong>Implementation Step:</strong></label>
                        </div>
                        <div className="slds-size_2-of-3">
                            <div className="slds-grid slds-grid_vertical-align-center">
                                <div className="slds-col">
                                    <ImplementationStepSelector
                                        changeCaseMetadata={isValidChangeCase ? ccMetadata : null}
                                        setSelectedStep={setSelectedImplStepID}
                                        disable={disableImplStep()}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {getBottomContent()}
            </div>
        </Modal>
    );
};

export default SCPRollbackOperationModal;
