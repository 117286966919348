import React, { useEffect, useState } from "react";
import {
    Card,
    Icon,
    Button,
    Accordion,
    AccordionPanel,
    Input, ButtonGroup, Dropdown
} from "@salesforce/design-system-react";
import { AddToastFunction } from "../../../../utils/types/toast";
import { CustomHeightPageSpinner } from "../../../../utils/components/spinner/page_spinner_overlay";
import { StackSetsDeploymentApiServiceFactory } from "../../../../services/deployment/stacksets/factory";
import { TOAST_MESSAGE } from "../../../../constants/toastMessages";
import {StackSetsDeployment} from "../../../../utils/types/deployments/stacksets/deployment";
import {StackSetOperation} from "../../../../utils/types/deployments/stacksets/operation";
import {StackSetStagger} from "../../../../utils/types/deployments/stacksets/stagger";
import BarChart, {
    BarChartRow,
    NoneBarChartRow,
    PrepareBarChartData
} from "../../../../utils/components/bar_chart/bar_chart";
import DeployStackSetCommitModal from "../../../../modals/pcsk_admin/deployments/stacksets/DeployNewCommitModal";
import ExpandStackSetCommitModal from "../../../../modals/pcsk_admin/deployments/stacksets/ExpandStackSetCommitModal";
import RevertStackSetCommitModal from "../../../../modals/pcsk_admin/deployments/stacksets/RevertStackSetCommitModal";
import {GenericDeploymentSubmissionResponse} from "../../../../utils/types/deployments/genericDeploymentResponse";
import QuickLinkList, {QuickLinkItem} from "../../../../utils/components/quick_links/QuickLinks";
import IconSpinnerOverlay from "../../../../utils/components/spinner/icon_spinner_overlay";
import ThemedBadge from "../../../../utils/components/tag/tag";
import {CONSTANT_GENERIC_DEPLOYMENTS} from "../../../../constants/themes";
import StaggerCard from "./StaggersCard";
import ChangesCard from "./ChangesCard";
import {extractUserIDFromEmail} from "./Utils";


const ITEMS_PER_PAGE = 10; // Define how many items to display per page

type StackSetDeploymentsProps = {
    addToast: AddToastFunction;
};

type FilterOption = {
    label: string;
    value: string;
};
const labelAll : FilterOption = {label: "All", value: "All"}

const PCSKAdminStackSetTemplateDeploymentsPage: React.FC<StackSetDeploymentsProps> = ({
                                                                                  addToast,
                                                                              }) => {
    const [stackSetsDeployments, setStackSetsDeployments] = useState<StackSetsDeployment[]>([]);
    const [filteredSetsDeployments, setFilteredSetsDeployments] = useState<StackSetsDeployment[]>([]);
    const [isFetchingDeployments, setIsFetchingDeployments] = useState<boolean>(true);
    const [selectedCommit, setSelectedCommit] = useState<StackSetsDeployment | null>(null);
    const [selectedStackSet, setSelectedStackSet] = useState<StackSetOperation | null>(null);
    const [selectedStagger, setSelectedStagger] = useState<StackSetStagger | null>(null);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [statusFilterOptions, setStatusFilterOptions] = useState<FilterOption[]>([labelAll])
    const [selectedStatusFilter, setSelectedStatusFilter] = useState<FilterOption>(labelAll)
    const [developerFilterOptions, setDeveloperFilterOptions] = useState<FilterOption[]>([labelAll])
    const [selectedDeveloperFilter, setSelectedDeveloperFilter] = useState<FilterOption>(labelAll)
    const [commitsBarChartData, setCommitsBarChartData] = useState<BarChartRow[]>([])
    const [stackSetsBarChartData, setStackSetsBarChartData] = useState<BarChartRow[]>([])
    const [isDeployNewCommitModalOpen, setIsDeployNewCommitModalOpen] = useState<boolean>(false);
    const [isExpandCommitModalOpen, setIsExpandCommitModalOpen] = useState<boolean>(false);
    const [isRevertCommitModalOpen, setIsRevertCommitModalOpen] = useState<boolean>(false);

    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = Math.min(startIndex + ITEMS_PER_PAGE, filteredSetsDeployments.length);
    const stackSetsApiService = StackSetsDeploymentApiServiceFactory.getService();

    useEffect(() => {
        fetchStackSetsDeployments();
    }, []);

    useEffect(() => {
        setSelectedStackSet(null); // Reset when switching commits
        const statusCounts = new Map<string, number>();

        // Count the number of unique items by deployment_status
        selectedCommit?.changes.forEach(item => {
            const status = item.stackset_status || "Unknown";
            // Update the map with the current count for each status, defaulting to 1 if not already present
            statusCounts.set(status, (statusCounts.get(status) ?? 0) + 1);
        });
        setStackSetsBarChartData(PrepareBarChartData(statusCounts));
    }, [selectedCommit]);

    useEffect(() => {
        setSelectedStagger(null); // Reset when switching stackset
        const statusCounts = new Map<string, number>();

        // Count the number of unique items by deployment_status
        selectedStackSet?.staggers.forEach(item => {
            const status = item.stackset_status || "Unknown";
            statusCounts.set(status, (statusCounts.get(status) ?? 0) + 1);
        });
    }, [selectedStackSet]);

    useEffect(() => {
        filterDeployments();
    }, [searchTerm, currentPage, stackSetsDeployments, selectedStatusFilter, selectedDeveloperFilter]);

    useEffect(() => {
        // Create a Set to store unique deployment statuses
        const uniqueStatuses = new Set(stackSetsDeployments.map(deployment => deployment.deployment_status));

        // Create the filter options from unique deployment statuses
        const filterByStatusOptions: FilterOption[] = Array.from(uniqueStatuses).map(status => ({
            label: status,
            value: status
        }));

        // Add "All" as an option
        filterByStatusOptions.push({ label: "All", value: "All" });
        filterByStatusOptions.sort((a, b) => a.value.localeCompare(b.value));

        setStatusFilterOptions(filterByStatusOptions);
    }, [stackSetsDeployments]);

    useEffect(() => {
        // Create a Set to store unique developers
        const uniqueDevelopers = new Set(stackSetsDeployments.map(deployment => deployment.developer_id));

        // Create the filter options from unique deployment statuses
        const filterByDeveloperOptions: FilterOption[] = Array.from(uniqueDevelopers).map(id => ({
            label: extractUserIDFromEmail(id),
            value: extractUserIDFromEmail(id)
        }));

        // Add "All" as an option
        filterByDeveloperOptions.push({ label: "All", value: "All" });
        filterByDeveloperOptions.sort((a, b) => a.value.localeCompare(b.value));

        setDeveloperFilterOptions(filterByDeveloperOptions);
    }, [stackSetsDeployments]);

    useEffect(() => {
        const statusCounts = new Map<string, number>();

        // Count the number of unique items by deployment_status
        filteredSetsDeployments.forEach(item => {
            const status = item.deployment_status || "Unknown";
            statusCounts.set(status, (statusCounts.get(status) ?? 0) + 1);
        });
        setCommitsBarChartData(PrepareBarChartData(statusCounts));
    }, [filteredSetsDeployments]);

    const fetchStackSetsDeployments = async () => {
        setIsFetchingDeployments(true);
        setStackSetsDeployments([])
        resetPage()
        try {
            const response = await stackSetsApiService.getDeployments();
            setStackSetsDeployments(response);
        } catch (error) {
            addToast("", TOAST_MESSAGE.FAILED_LOADING_STACK_SET_DEPLOYMENTS(`${error}`), "error");
        } finally {
            setIsFetchingDeployments(false);
        }
    };

    const handleSelectCommit = (commit: StackSetsDeployment) => {
        setSelectedCommit(selectedCommit === commit ? null : commit);
    };

    const handleSelectStackSet = (operation: StackSetOperation) => {
        setSelectedStackSet(selectedStackSet === operation ? null : operation);
    };

    const handleSelectStagger = (stagger: StackSetStagger) => {
        setSelectedStagger(selectedStagger === stagger ? null : stagger);
    };

    const handleExpand = () => {
        setIsExpandCommitModalOpen(true)
    };

    const handleRevert = () => {
        setIsRevertCommitModalOpen(true)
    };

    // Filter deployments based on search term
    const filterDeployments = () => {
        let data = stackSetsDeployments.filter(deployment => {
            return (
                deployment.commit_id.toLowerCase().includes(searchTerm.toLowerCase()) ||
                deployment.commit_title.toLowerCase().includes(searchTerm.toLowerCase())
            );
        });

        if (selectedStatusFilter.value != labelAll.value) {
            data = data.filter(deployment => {
                return deployment.deployment_status == selectedStatusFilter.value;
            });
        }

        if (selectedDeveloperFilter.value != labelAll.value) {
            data = data.filter(deployment => {
                return deployment.developer_id.includes(selectedDeveloperFilter.value);
            });
        }

        // Sort by updated_at in descending order
        data.sort((a, b) => b.updated_at - a.updated_at);
        setFilteredSetsDeployments(data)
    };

    // Get the current page's deployments
    const getCurrentPageDeployments = () => {
        return filteredSetsDeployments.slice(startIndex, endIndex);
    };

    const resetPage = () => {
        setSelectedCommit(null)
        setSelectedStackSet(null)
        setSelectedStagger(null)
    }

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1); // Reset to first page when searching
        resetPage()
    };

    const handleNextPage = () => {
        if ((currentPage * ITEMS_PER_PAGE) < filteredSetsDeployments.length) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(prevPage => prevPage - 1);
        }
    };

    const getCommitsBarChartSize = () => {
        return `slds-size_1-of-${commitsBarChartData.length <= 0 ? 1 : commitsBarChartData.length}`
    }
    const getStackSetsBarChartSize = () => {
        return `slds-size_1-of-${stackSetsBarChartData.length <= 0 ? 1 : stackSetsBarChartData.length}`
    }
    const getDetailsLi = (key: string, value: string) => {
        return <>
            <li>
                <b className="slds-text-color--weak slds-text-body_small">{key}:</b> {value}
            </li>
        </>
    }

    const getDeploymentsCard = () => {
        if (isFetchingDeployments || stackSetsDeployments.length > 0) {
            return <>
                {/* First Box: Commits */}
                <Card
                    heading={<>
                        <div className="slds-grid slds-grid_align-spread">
                            <h1 className={"slds-align-content-center"}>PCSK StackSet Template Deployments</h1>
                            <div className="slds-grid slds-grid_vertical-align-center ">
                                <Button
                                    label="Deploy New Commit"
                                    className="slds-button slds-button_outline-brand slds-m-left_small slds-m-right_medium"
                                    onClick={() => setIsDeployNewCommitModalOpen(true)}
                                    disabled={isFetchingDeployments}
                                />

                                <IconSpinnerOverlay
                                    size="x-small"
                                    loading={isFetchingDeployments}
                                    onClick={fetchStackSetsDeployments}
                                />
                            </div>
                        </div>
                    </>}
                    icon={<Icon category="standard" name="code_playground" size="small"/>}
                    className={"slds-m-horizontal--small"}
                    bodyClassName={"slds-p-horizontal--large slds-p-bottom--large "}
                    style={{minHeight: "50vh"}}
                >
                    {/* Bar Chart */}
                    {isFetchingDeployments ? <CustomHeightPageSpinner size="medium" minHeight="50vh"/> : <>
                        {
                            filteredSetsDeployments.length > 0 ? <div className="slds-grid slds-wrap">
                                    <div className="slds-col">
                                        <BarChart
                                            heading={"Commit Status Distribution"}
                                            data={commitsBarChartData}
                                            liClass={getCommitsBarChartSize()}
                                            headerClass={"slds-text-title_bold slds-text-color--weak"}
                                            contentText={"Commit"}
                                        />
                                    </div>
                                </div> :
                                <div className="slds-grid slds-wrap">
                                    <div className="slds-col">
                                        <BarChart
                                            heading={"Commit Status Distribution"}
                                            data={NoneBarChartRow()}
                                            liClass={getCommitsBarChartSize()}
                                            headerClass={"slds-text-title_bold slds-text-color--weak"}
                                            contentText={"Commit"}
                                        />
                                    </div>
                                </div>
                        }

                        <div
                            className="slds-grid slds-wrap slds-m-bottom_small slds-m-top--x-small">
                            {/* Search bar */}
                            <div className="slds-col slds-size_6-of-12 slds-p-right_small slds-text-color--weak">
                                <h2 className={"slds-text-title_bold"}> Search Commits</h2>
                                <Input
                                    value={searchTerm}
                                    onChange={handleSearchChange}
                                    placeholder="Search by Commit ID, Title"
                                    className="slds-m-bottom_small slds-m-top--x-small"
                                    disabled={isFetchingDeployments}
                                />
                            </div>

                            <div className="slds-m-left_small">
                                <label
                                    className="slds-form-element__label slds-text-title_bold slds-text-color--weak slds-m-bottom--xx-small">Developer</label>
                                <div>
                                    <Dropdown
                                        align="left"
                                        iconCategory="utility"
                                        iconName="filter"
                                        iconPosition="right"
                                        label={selectedDeveloperFilter.label}
                                        options={developerFilterOptions}
                                        onSelect={(selectedFilter: FilterOption) => {
                                            setSelectedDeveloperFilter(selectedFilter)
                                        }}
                                        disabled={isFetchingDeployments}
                                    />
                                </div>
                            </div>

                            <div className="slds-m-left_small">
                                <label
                                    className="slds-form-element__label slds-text-title_bold slds-text-color--weak slds-m-bottom--xx-small">Commit
                                    Status</label>
                                <div>
                                    <Dropdown
                                            align="left"
                                            iconCategory="utility"
                                            iconName="filter"
                                            iconPosition="right"
                                            label={selectedStatusFilter.label}
                                            options={statusFilterOptions}
                                            onSelect={(selectedFilter: FilterOption) => {
                                                setSelectedStatusFilter(selectedFilter)
                                            }}
                                            disabled={isFetchingDeployments}
                                        />
                                </div>
                            </div>
                        </div>
                        {/* Pagination display */}
                        {Math.min(endIndex, filteredSetsDeployments.length) > 0 &&
                            <div
                                className="slds-grid slds-m-bottom--x-small slds-m-top_medium slds-box slds-color__background_gray-2">
                                <div
                                    className="slds-col slds-size_1-of-2 slds-align-middle slds-m-left_medium slds-text-title_bold slds-text-color_success">
                                    <span>
                                        Showing {startIndex + 1} - {Math.min(endIndex, filteredSetsDeployments.length)} of total {filteredSetsDeployments.length}
                                    </span>
                                </div>
                                {/* Pagination buttons */}
                                <div className="slds-col slds-size_1-of-2 slds-text-align--right">
                                    <ButtonGroup className="slds-m-right_medium">
                                        <Button
                                            label="Previous"
                                            variant="neutral"
                                            onClick={handlePrevPage}
                                        disabled={currentPage <= 1}
                                    />
                                    <Button
                                        label="Next"
                                        onClick={handleNextPage}
                                        disabled={(currentPage * ITEMS_PER_PAGE) >= filteredSetsDeployments.length}
                                    />
                                </ButtonGroup>
                            </div>
                        </div>
                    }
                    <Accordion>
                        {getCurrentPageDeployments().map((deployment, index) => (
                            <AccordionPanel
                                key={index}
                                expanded={selectedCommit?.commit_id === deployment.commit_id}
                                onTogglePanel={() => handleSelectCommit(deployment)}
                                summary={
                                    <div className="slds-text-wrap" style={{whiteSpace: 'normal'}}>
                                        <ThemedBadge
                                            className={"slds-m-right_medium"}
                                            theme={CONSTANT_GENERIC_DEPLOYMENTS.GET_STATUS_THEME(deployment.deployment_status, true)}
                                            text={deployment.deployment_status}
                                        />
                                        {`${deployment.commit_title}`}
                                    </div>
                                }
                            >
                                <div className="slds-grid slds-box">
                                    <div
                                        className="slds-col slds-size_3-of-4 slds-m-horizontal--x-small">

                                        <ul className="slds-list_dotted">
                                            {getDetailsLi("Commit ID", deployment.commit_id)}
                                            {getDetailsLi("Developer", deployment.developer_id)}
                                            {getDetailsLi("Created At", deployment.getCreatedAtDate())}
                                            {getDetailsLi("Updated At", deployment.getUpdatedAtDate())}
                                        </ul>
                                    </div>
                                    {deployment && deployment.hasActions() && <Card
                                        heading="Commit Actions"
                                        icon={<Icon category="standard" name="actions_and_buttons"
                                                    size="small"/>}
                                        bodyClassName={"slds-p-right_medium slds-p-left_medium slds-p-bottom_medium"}
                                    >
                                        <div className="slds-row slds-size_1-of-4 slds-text-align--left">
                                            <div className="slds-button-group slds-vertical">
                                                {deployment.can_expand && <Button
                                                    label="Expand"
                                                    variant="neutral"
                                                    className="slds-m-bottom_small slds-m-around_small"
                                                    onClick={handleExpand}
                                                />}
                                                {deployment.can_revert && <Button
                                                    label="Revert"
                                                    variant="text-destructive"
                                                    className="slds-m-bottom_small slds-m-around_small"
                                                    onClick={handleRevert}
                                                />}
                                            </div>
                                        </div>
                                    </Card>}
                                </div>
                            </AccordionPanel>
                        ))}
                    </Accordion>
                    </>}
                </Card>
            </>
        }

        return <>
            <div className="slds-size_1-of-1">
                <Card
                    heading="PCSK StackSet Templates Deployments"
                    icon={<Icon category="standard" name="code_playground" size="small"/>}
                    className="slds-m-horizontal--small slds-m-top--small"
                    bodyClassName={"slds-p-right_medium slds-p-left_medium"}
                >
                    <div className="slds-m-top_medium slds-m-bottom_medium slds-text-align_center">
                        <div
                            className="slds-text-title">
                            <p className="slds-text-heading_medium slds-text-color_default">
                                Found No StackSets Deployments
                            </p>
                            <Button
                                className={"slds-m-top--medium"}
                                label="Deploy New Commit"
                                variant="brand"
                                onClick={() => {
                                    setIsDeployNewCommitModalOpen(true)
                                }}
                                disabled={isFetchingDeployments}
                            />
                        </div>
                    </div>
                </Card>
            </div>
        </>
    }

    const getChangesCard = () => {
        return<ChangesCard
            selectedCommit={selectedCommit}
            selectedStackSet={selectedStackSet}
            stackSetsBarChartData={stackSetsBarChartData}
            handleSelectStackSet={handleSelectStackSet}
            getStackSetsBarChartSize={getStackSetsBarChartSize}
            getStaggersCard={getStaggersCard}
            CONSTANT_GENERIC_DEPLOYMENTS={CONSTANT_GENERIC_DEPLOYMENTS}
        />
    }


    const getStaggersCard = () => {
        return <StaggerCard
            selectedCommit={selectedCommit}
            selectedStackSet={selectedStackSet}
            selectedStagger={selectedStagger}
            handleSelectStagger={handleSelectStagger}
            getDetailsLi={getDetailsLi}
        />
    }

    // Useful links Data
    const usefulPCSKLinks: QuickLinkItem[] = [
        {text: "Admin Stacksets Deployment Guide", link: "https://salesforce.quip.com/ntQgAbKVstB9"},
        {text: "Deployment Runbook", link: "https://salesforce.quip.com/WBooASITifGJ"},
        {text: "StackSets Repo", link: "https://git.soma.salesforce.com/identity-access/pcsk-stacksets"},
        {text: "PCSK Troubleshooting Guide", link: "https://salesforce.quip.com/1c4HA0Gl48Vr" },
        {text: "PCSK Availability", link: "https://tabse.internal.salesforce.com/views/UnifiedServiceHealthREADS/SFHealthTrend?Senior%20Engineering%20Leader=Paul%20Constantinides&Service%2FFeature=pcsk-jit&%3Aembed=y&%3Aoriginal_view=y#1" },
    ];

    return <>
        <QuickLinkList usefulPCSKLinks={usefulPCSKLinks} />
        <div className={"slds-grid"}>
            <div className="slds-col slds-size_5-of-8">
                {getDeploymentsCard()}
            </div>
            <div className="slds-col slds-size_3-of-8">
                {getChangesCard()}
            </div>
        </div>

        {isDeployNewCommitModalOpen && <DeployStackSetCommitModal
            isOpen={isDeployNewCommitModalOpen}
            onClose={() => setIsDeployNewCommitModalOpen(false)}
            onDeploySuccess={(commitID: string, apiResponse: GenericDeploymentSubmissionResponse) => {
                addToast("Deployment Request Submitted Successfully!", `commit: ${commitID} has been submitted for deployment.`, "success");
                setIsDeployNewCommitModalOpen(false)
            }}
        />}

        {isExpandCommitModalOpen && selectedCommit && <ExpandStackSetCommitModal
            isOpen={isExpandCommitModalOpen}
            onClose={() => setIsExpandCommitModalOpen(false)}
            selectedCommit={selectedCommit}
            onExpandSuccess={(apiResponse: GenericDeploymentSubmissionResponse) => {
                addToast("Expand Request Submitted Successfully!", `commit: ${selectedCommit?.commit_id} has been submitted for Expansion.`, "success");
                setIsExpandCommitModalOpen(false)
            }}
        />}

        {isRevertCommitModalOpen && selectedCommit && <RevertStackSetCommitModal
            isOpen={isRevertCommitModalOpen}
            onClose={() => setIsRevertCommitModalOpen(false)}
            selectedCommit={selectedCommit}
            onRevertSuccess={(apiResponse: GenericDeploymentSubmissionResponse) => {
                addToast("Revert Request Submitted Successfully!", `commit: ${selectedCommit?.commit_id} has been submitted for Revert.`, "success");
                setIsRevertCommitModalOpen(false)
            }}
        />}
    </>
};

export default PCSKAdminStackSetTemplateDeploymentsPage;
