import {MainGUSApiService} from "./api_repository"
import {GUSApiService} from "./service"
import {MockGUSApiService} from "./mock_api_repository";
import {API_MOCK_STATUS} from "../environment/environment";
export class GUSApiServiceFactory {
    static getService(): GUSApiService {
        if (API_MOCK_STATUS.isGUSMockEnabled()) {
            return new MockGUSApiService();
        } else {
            return new MainGUSApiService();
        }
    }
}
