import React, {useEffect, useState} from 'react';
import {Card, Icon,} from '@salesforce/design-system-react';
import {useNavigate} from "react-router-dom";
import {URL_PATHS} from "../../../constants/pcsk_admin/paths";
import BarChart, {BarChartRow, GetOptimalBarContainerSize} from "../../../utils/components/bar_chart/bar_chart";
import {CustomHeightPageSpinner} from "../../../utils/components/spinner/page_spinner_overlay";
import IconSpinnerOverlay from "../../../utils/components/spinner/icon_spinner_overlay";
import {GenericServiceSummary} from "../../../utils/types/genericServiceSummaryItem";
import {SCPDeploymentAPIServiceFactory} from "../../../services/deployment/scp/factory";

const SCPOperationsSummaryCard: React.FC = () => {
    const [isFetchingSummary, setIsFetchingSummary] = useState<boolean>(false);
    const [fetchingSummaryError, setFetchingSummaryError] = useState<string>("");
    const navigate = useNavigate();
    const [scpOperationsSummary, setScpOperationsSummary] = useState<GenericServiceSummary | null>(null);
    const [barChartData, setBarChartData] = useState<BarChartRow[]>([])

    const scpOperationsAPIService = SCPDeploymentAPIServiceFactory.getService();

    useEffect(() => {
        fetchSCPDriftSummary()
    }, []);

    const fetchSCPDriftSummary = async () => {
        setIsFetchingSummary(true);
        setFetchingSummaryError("")
        try {
            const summaries = await scpOperationsAPIService.getSummary()
            setScpOperationsSummary(summaries)
        } catch (error) {
            setFetchingSummaryError(`${error}`)
        } finally {
            setIsFetchingSummary(false);
        }
    };

    useEffect(() => {
        scpOperationsSummary && setBarChartData(scpOperationsSummary.generateBarChartData());
    }, [scpOperationsSummary]);

    const getSummaryBody = () => {
        if (fetchingSummaryError !== '') {
            return (
                <div className="slds-grid slds-wrap slds-m-around_medium slds-text-color_destructive">
                    <p>{fetchingSummaryError}&nbsp;</p>
                </div>
            );
        }

        return (
            <div className="slds-grid slds-wrap">
                <div className="slds-col slds-size_1-of-1 slds-m-right_medium">
                    <BarChart
                        heading={""}
                        data={barChartData}
                        headerClass={"slds-text-title_caps slds-m-left_small"}
                        contentText={"operation"}
                    />
                </div>
            </div>
        )
    };

    return (<>
        <div className={`slds-col ${GetOptimalBarContainerSize(barChartData.length, 4)} slds-p-around--x-small`}>
            <Card
                className="slds-card slds-card_boundary"
                heading={
                    <div className="slds-grid slds-grid_align-spread">
                        <button
                            className="slds-button slds-button_reset slds-text-heading_small slds-text-link"
                            onClick={() => navigate(URL_PATHS.PCSK_ADMIN_SCP_DEPLOYMENTS_PAGE)}
                            title="Navigate to SCP Operations"
                        >
                            SCP Operations
                        </button>
                        <IconSpinnerOverlay size={"x-small"} loading={isFetchingSummary} onClick={fetchSCPDriftSummary}/>
                    </div>
                }
                icon={<Icon
                    category="standard"
                    name="flow"
                    size="small"
                />}
                style={{minHeight: '15vh'}}
            >
                {isFetchingSummary ? <>{<CustomHeightPageSpinner size="small" minHeight="5.2vh"/>}</> :
                    <>
                        {getSummaryBody()}
                    </>
                }
            </Card>
        </div>
    </>);
};

export default SCPOperationsSummaryCard;
