import React, { useState, useEffect } from 'react';
import { Combobox, comboboxFilterAndLimit, Dropdown } from '@salesforce/design-system-react';
import { ArtifactsApiServiceFactory } from "../../../services/sam/factory";
import CustomSpinnerOverlay from "../spinner/custom_spinner_overlay";

interface ArtifactSelectorProps {
    setArtifact: React.Dispatch<React.SetStateAction<string>>;
    disable: boolean;
    removeSelection: boolean;
    prefix: string;
    filterContaining: string[];
}

const ThreeMonths: number = 180
const MaxRepetitions = 255; // Set the maximum number of stack depth for artifact fetching limit

const ArtifactSelector: React.FC<ArtifactSelectorProps> = ({
                                                             setArtifact,
                                                             disable, prefix,
                                                             filterContaining,
                                                             removeSelection,
                                                         }) => {
    const [selectedArtifact, setSelectedArtifact] = useState<string>('');
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [filteredArtifacts, setFilteredArtifacts] = useState<{ id: string; label: string }[]>([]);
    const [isFetchingArtifacts, setIsFetchingArtifacts] = useState<boolean>(false);
    const [isDisabled, setIsDisabled] = useState<boolean>(disable);
    const [artifacts, setArtifacts] = useState<string[]>([]);
    const [error, setError] = useState<string>(''); // Error state

    const artifactApiService = ArtifactsApiServiceFactory.getService();

    useEffect(() => {
        resetFC()
    }, []);

    useEffect(() => {
        !disable && !artifacts.length && fetchArtifacts();
    }, [disable]);

    useEffect(() => {
        setSelectedArtifact('');
        setSearchTerm('');
        setError('');
    }, [removeSelection]);

    useEffect(() => {
        setArtifact(selectedArtifact)
    }, [selectedArtifact]);

    useEffect(() => {
        setIsDisabled(disable || isFetchingArtifacts)
        if (!disable && !isFetchingArtifacts && artifacts.length == 1) {
            setSelectedArtifact(artifacts[0])
        }
    }, [isFetchingArtifacts, disable]);

    const resetFC = () => {
        setSelectedArtifact('');
        setSearchTerm('');
        setError('');
        setArtifacts([]);
    }

    // Effect to filter commits whenever searchTerm or commits change
    useEffect(() => {
        const filtered = artifacts
            .filter((artifact) => artifact.toLowerCase().includes(searchTerm.toLowerCase()))
            .map((commit) => ({ id: commit, label: commit }));
        setFilteredArtifacts(filtered);
    }, [searchTerm, artifacts, setArtifacts]);

    const fetchArtifacts = async () => {
        try {
            // Reset state before starting the fetch process
            resetFC();
            setIsFetchingArtifacts(true);

            let allArtifacts: string[] = [];
            let currentMarker = "";
            let isFetching = true;
            let iterationCount = 0;

            while (isFetching) {
                if (iterationCount >= MaxRepetitions) {
                    console.warn("Maximum stack repetitions reached. Stopping fetch to prevent infinite loop.");
                    break; // Exit the loop if the max number of repetitions is reached
                }

                // Fetch 3 pages of artifacts at a time
                const response = await artifactApiService.getArtifacts(prefix, 3, ThreeMonths, currentMarker, filterContaining);

                if (response && Array.isArray(response.artifactNames)) {
                    // Append the new artifacts to the existing list
                    allArtifacts = [...allArtifacts, ...response.artifactNames];
                    setArtifacts(allArtifacts);
                }

                // Check if response and marker exist
                if (response && response.marker !== undefined) {
                    currentMarker = response.marker;
                } else {
                    currentMarker = ""; // Explicitly clear the marker if response is invalid
                }

                // Stop fetching if marker is empty
                if (!currentMarker) {
                    isFetching = false;
                }

                iterationCount++; // Increment the iteration counter
            }
        } catch (err: any) {
            console.error('Failed to load artifacts.', err);

            // Set a meaningful error message
            let mssg = 'Failed to load artifacts. Please try again later.';
            if (err && typeof err.message === 'string') {
                mssg = err.message;
            }
            setError(mssg);
        } finally {
            setIsFetchingArtifacts(false);
        }
    };

    return (
        <>
            <div className="slds-size_1-of-1">
                {isDisabled ? <>
                    <div className="slds-input-has-spinner slds-grid slds-grid_vertical-align-center">
                        <input
                            type="text"
                            className={`slds-input ${isDisabled ? 'slds-is-disabled' : ''}`}
                            placeholder="Search by Commit ID or Artifact Name"
                            value={searchTerm}
                            disabled={isDisabled}
                        />
                        {isFetchingArtifacts && (
                            <div className="slds-m-left_large">
                                <CustomSpinnerOverlay size="small" />
                            </div>
                        )}
                    </div>
                    </>
                    :
                    <Combobox
                        id="artifact_picker_combobox"
                        className={""}
                        events={{
                            onSelect: (event: any, data: any) => {
                                if (data.selection && data.selection.length > 0) {
                                    setSelectedArtifact(data.selection[0].id);
                                    setSearchTerm(data.selection[0].label);
                                }
                            },
                            onChange: (event: any, data: any) => {
                                setSearchTerm(data.value);
                            },
                            onRequestRemoveSelectedOption: (event: any, data: any) => {
                                setSelectedArtifact('');
                                setSearchTerm('');
                            },
                        }}
                        labels={{
                            placeholder: 'Search by Commit ID or Artifact Name',
                            noOptionsFound: isFetchingArtifacts ? '' : 'No Matches Found.',
                        }}
                        required
                        menuPosition="absolute"
                        options={
                            isFetchingArtifacts
                                ? []
                                : comboboxFilterAndLimit({
                                    inputValue: searchTerm,
                                    options: filteredArtifacts,
                                    selection: selectedArtifact ? [{ id: selectedArtifact, label: selectedArtifact }] : [],
                                    limit: 40000,
                                })
                        }
                        value={searchTerm}
                        selection={selectedArtifact ? [{ id: selectedArtifact, label: selectedArtifact }] : []}
                        variant="inline-listbox"
                        menuItemVisibleLength={5}
                        hasMenuSpinner={isFetchingArtifacts}
                    />
                }
                {/* Error message below Combobox */}
                {error && <div className="slds-text-color_error slds-m-top_xx-small">{error}</div>}
            </div>
        </>
    );
};

export default ArtifactSelector;
